<template>
  <div class="modal-overlay" @click.self="$emit('close-modal')">
    <div class="modal">
      <button class="close-button" @click="$emit('close-modal')">✕</button>
      <h6>{{ textoTituloFatura }}</h6>
      <p>{{ textoDescricao }}</p>

      <div class="search-bar-container">
        <select v-model="searchType" class="search-select">
          <option value="cnpj">CNPJ</option>
          <option value="transportador">Transportador</option>
          <option value="valor">Valor</option>
        </select>
        <input
          v-model="searchQuery"
          @input="filterFaturas"
          type="text"
          placeholder="Pesquisar..."
          class="search-bar"
        />
      </div>

      <div class="table-container">
        <table class="faturas-table">
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Transportador</th>
              <th>Baixar</th>
              <th>PIX</th>
              <th>QR Code Pix</th>
              <th>Referência</th>
              <th>Qtd CTEs</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(fatura, index) in filteredFaturas" :key="index">
              <td>{{ fatura.transportador.cnpj }}</td>
              <td>{{ fatura.transportador.nomeFantasia }}</td>
              <td><a @click.prevent="downloadPDF(fatura.linkFatura)" href="#">PDF</a></td>
              <td><a @click.prevent="copiarPix(fatura.codigoPix)" href="#">PIX</a></td>
              <td>
                <a @click.prevent="showQRCodeModal(fatura.codigoPix)" href="#">QrCodePix</a>
              </td>
              <td>{{ fatura.mes }} / {{ fatura.ano }}</td>
              <td>{{ fatura.qtde }}</td>
              <td class="valor">{{ fatura.valorTotal.toFixed(2).replace('.', ',') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="fixed-footer">
        <a :href="linkWhatsApp" class="whats-link">
          <img src="https://ik.imagekit.io/d6rcr6hta/whatsapp.png?updatedAt=1730644409968" alt="WhatsApp" class="whats-icon" />
          Whatsapp {{ foneCobranca.ddd }}{{ foneCobranca.numero }}
        </a>
        <button @click="$emit('close-modal')" class="confirm-button">OK</button>
      </div>

      <!-- Modal para exibir o QR Code em tamanho grande -->
      <div v-if="qrCodeModalVisible" class="qr-code-modal-overlay" @click.self="qrCodeModalVisible = false">
        <div class="qr-code-modal">
          <img :src="largeQRCode" alt="QR Code Pix" class="qr-code-image" />
          <button @click="qrCodeModalVisible = false" class="close-button-below">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Movimentacao from "../service/movimentacao";
import QRCode from "qrcode";
import { mapActions } from "vuex";

export default {
  props: {
    fraseFatPend: String,
  },
  data() {
    return {
      texto: this.fraseFatPend,
      faturas: [],
      filteredFaturas: [],
      searchQuery: '',
      searchType: 'cnpj',
      foneCobranca: '',
      linkWhatsApp: '',
      textoTituloFatura: 'Faturas Pendentes',
      textoDescricao: 'Faturas vencidas há mais de 7 dias resultarão na suspensão da emissão de novos documentos. Ainda não identificamos o pagamento para as faturas abaixo. Caso o pagamento tenha sido efetuado, envie-nos o comprovante no whatsapp abaixo',
      qrCodeModalVisible: false,
      currentQRCode: '',
      largeQRCode: ''
    };
  },
  mounted() {
    Movimentacao.getAllFaturas().then((response) => {
      var faturasPendentes = response.data;
      if (faturasPendentes.transportadores && faturasPendentes.transportadores.length > 0) {
        this.mostrarFaturasPendentes(faturasPendentes);
      } else {
        this.$emit('semFaturas');
      }
    });
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    mostrarFaturasPendentes(faturasPendentes) {
      var faturasPendentesAjustadas = [];
      var temVencido = false;

      for (var i = 0; i < faturasPendentes.transportadores.length; i++) {
        var transportador = faturasPendentes.transportadores[i];

        for (var j = 0; j < transportador.debitos.length; j++) {
          var debito = transportador.debitos[j];
          debito.transportador = transportador;

          var linkFatura = `https://cte-engine-api-drygfuxawq-ue.a.run.app/v1/cte/faturas/${debito.transportador.cnpj}/referencia/${debito.ano}/${debito.mes}`;
          debito.linkFatura = linkFatura;

          faturasPendentesAjustadas.push(debito);

          this.foneCobranca = faturasPendentes.foneCobranca;
          this.linkWhatsApp = `https://api.whatsapp.com/send?phone=${this.foneCobranca.ddd}${this.foneCobranca.numero}`;

          if (debito.status === "VENCIDO") temVencido = true;
        }
      }

      this.faturas = faturasPendentesAjustadas;
      this.filteredFaturas = faturasPendentesAjustadas;
      this.texto = faturasPendentesAjustadas.length === 1
        ? "Há uma fatura pendente de pagamento"
        : `Há ${faturasPendentesAjustadas.length} faturas pendentes de pagamento`;

      if (temVencido) {
        this.$emit('show-modal');
      } else {
        this.textoTituloFatura = faturasPendentesAjustadas.length === 1 ? "Fatura em aberto" : "Faturas em aberto";
        this.textoDescricao = "Há faturas em aberto, mas ainda dentro do prazo";
      }
    },
    filterFaturas() {
      const query = this.searchQuery.toLowerCase();
      this.filteredFaturas = this.faturas.filter(fatura => {
        if (this.searchType === 'cnpj') {
          return fatura.transportador.cnpj.toLowerCase().includes(query);
        } else if (this.searchType === 'transportador') {
          return fatura.transportador.nomeFantasia.toLowerCase().includes(query);
        } else if (this.searchType === 'valor') {
          return fatura.valorTotal.toFixed(2).toLowerCase().includes(query);
        }
        return false;
      });
    },
    async downloadPDF(url) {
      const pdfUrl = url;
      const authToken = localStorage.getItem('token');

      try {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${authToken}`);
        const response = await fetch(pdfUrl, { headers });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'fatura.pdf');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Erro ao baixar o PDF:', error);
      }
    },
    copiarPix(conteudo) {
      var input = document.createElement("input");
      input.setAttribute("value", conteudo);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.snackSuccess('Código PIX copiado');
    },
    async generateQRCode(pixCode, size = 100) {
      try {
        return await QRCode.toDataURL(pixCode, { width: size });
      } catch (error) {
        console.error("Erro ao gerar QR Code:", error);
      }
    },
    async showQRCodeModal(pixCode) {
      this.largeQRCode = await this.generateQRCode(pixCode, 600);
      this.qrCodeModalVisible = true;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 1400px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
}
  .close-button-below {
    margin-top: 50px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

.search-bar-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.search-select {
  padding: 8px;
  height:40px;
  width:150px;
  border-radius: 4px;
  border: 1px solid #ddd;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-color: white;
  background-size: 16px;
}

.search-bar {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.table-container {
  flex: 1;
  overflow-y: auto;
}

.qr-code-miniature {
  width: 30px;
  cursor: pointer;
}

.qr-code-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.qr-code-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 600px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Estilo para dispositivos móveis */
@media (max-width: 768px) {
  .qr-code-modal {
    width: 60vw;
    height: 60vw;
  }
  
  .qr-code-image {
    width: 60vw;
    height: 60vw;
  }

  .close-button-below {
    margin-top: 50px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-button-below:hover {
    background-color: #0056b3;
}
}

h6 {
  font-weight: 600;
  font-size: 24px;
  color: #333;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin: 10px 0 20px;
  color: #555;
}

.faturas-table {
  width: 100%;
  border-collapse: collapse;
}

.faturas-table th,
.faturas-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.faturas-table th {
  position: sticky;
  top: 0;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  z-index: 1;
}

.faturas-table .valor {
  text-align: right;
  padding-right: 10px;
  color: #006400;
}

.fixed-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  width: 100%;
}

.whats-link {
  display: flex;
  align-items: center;
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

.whats-icon {
  width: 20px;
  margin-right: 5px;
}

.confirm-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #0056b3;
}
</style>
