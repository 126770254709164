<template>
  <v-container data-app grid-list-xl fluid style="margin-top: 1vh">
    <ShowMessage ref="message"></ShowMessage>
    <v-layout row wrap>
      <v-flex>
        <BaseWidget title="Cadastro de Transportador">
          <v-layout row wrap>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Pesquisar por:</b></v-label>
                <v-select
                  v-model="pesquisaSelecionado"
                  :items="listaPesquisa"
                  :item-text="'description'"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6">
                <v-label><b style="color: transparent">P</b></v-label>
                <v-text-field
                  :label="'Digite os dados do ' + pesquisaSelecionadoDescription"
                  :disabled="!pesquisaSelecionado"
                  v-if="pesquisaSelecionado != 'Transportador'"
                  solo
                  v-model="tfPesquisa"
                  @input="tfPesquisa = $event.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Situação:</b></v-label>
                <v-select
                  v-model="statusSelecionado"
                  :items="listaStatus"
                  :item-text="'description'"
                  solo
                ></v-select>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="2"
                style="text-align: right; align-items: center; display: flex"
              >
                <div class="text-center mt-3">
                  <v-btn
                    dark
                    rounded=""
                    style="background-color: #1d4ed8"
                    @click="filtrar()"
                  >
                    <v-icon left> mdi-filter </v-icon>
                    <b>Filtrar</b>
                  </v-btn>
                </div>
              </v-col>

              <br />
              <v-col class="d-flex" cols="12" sm="4" style="text-align: left"></v-col>
            </v-row>
          </v-layout>
        </BaseWidget>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar style="background-color: #1d4ed8" class="elevation-1">
          <v-toolbar-title>
            <h5 style="color: white">Lista de Transportadores</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #1d4ed8"
            @click="novoTransportador()"
          >
            <v-icon left> mdi-plus </v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>
        <v-skeleton-loader v-if="showLoader" type="table"></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headers"
              :items="listaTransportadores"
              v-model="selected"
              item-key="idDocumento"
              class="elevation-1"
              :single-select="singleSelect"
            >
              <template v-slot:item.ativo="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColor(item.ativo)"
                  dark
                  >{{ item.ativo ? "Ativo" : "Inativo" }}
                </v-chip>
              </template>

              <template v-slot:item.preCadastro="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorPreCadastro(item.preCadastro)"
                  dark
                  >{{ item.preCadastro ? "Aguardando Liberação" : "Aprovado" }}
                </v-chip>
              </template>

              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarTransportador(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Editar Transportador"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="certificadoTransportador(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Cadastro de Certificado"
                    >mdi-shield-key</v-icon
                  >
                </v-btn>
                <v-btn @click="usuarioTransportador(item)" small>
                  <v-icon
                    :style="{ color: '#1d4ed8' }"
                    title="Cadastrar Usuário Transportador"
                    >mdi-account</v-icon
                  >
                </v-btn>
                <v-btn @click="transportadorConfig(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Configurações Abervação"
                    >mdi-account-key</v-icon
                  >
                </v-btn>
                <v-btn @click="liberarPreCadastro(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Liberar Pré-Cadastro"
                    >mdi-account-check</v-icon
                  >
                </v-btn>
                <v-btn @click="listarMotoristas(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Cadastro de Motorista"
                    >mdi-steering</v-icon
                  >
                </v-btn>
                <v-btn @click="listarVeiculos(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Cadastro de Veículo"
                    >mdi-car</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogCadastro" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Transportador</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                label="Código"
                v-model="item.id"
                readonly
                :disabled="item.id > 0 ? true : camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="cpfCnpjInput"
                label="CNPJ"
                v-model="item.cpfCnpj"
                maxlength="18"
                :readonly="item.id > 0"
                @input="formatarCnpj(item.cpfCnpj)"
                @blur="consultarCnpj"
                :disabled="item.id > 0 ? true : camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                ref="razaoSocialInput"
                label="Razão Social"
                maxlength="60"
                v-model="item.razaoSocial"
                @input="item.razaoSocial = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                ref="nomeFantasiaInput"
                label="Nome Fantasia"
                maxlength="60"
                v-model="item.nomeFantasia"
                @input="item.nomeFantasia = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                ref="inscricaoEstadualInput"
                label="Inscrição Estadual"
                maxlength="14"
                v-model="item.inscricaoEstadual"
                v-numbers-only
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                ref="logradouroInput"
                label="Logradouro"
                maxlength="60"
                v-model="item.logradouro"
                @input="item.logradouro = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                ref="numeroInput"
                label="Número"
                maxlength="10"
                v-model="item.numero"
                @input="item.numero = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                ref="cepInput"
                label="CEP"
                v-model="item.cep"
                @input="aplicarMascaraCep"
                maxlength="8"
                :disabled="camposDesabilitado"
                v-numbers-only
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                ref="bairroInput"
                label="Bairro"
                maxlength="60"
                v-model="item.bairro"
                @input="item.bairro = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                ref="complementoInput"
                label="Complemento"
                maxlength="60"
                v-model="item.complemento"
                @input="item.complemento = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-select
                ref="ufInput"
                v-model="item.uf"
                :items="estados"
                label="UF"
                item-text="sigla"
                item-value="sigla"
                @input="loadMunicipios"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
            <v-col cols="12" md="7">
              <v-select
                ref="municipioInput"
                v-model="item.municipio"
                :items="municipios"
                label="Município"
                item-text="nome"
                item-value="nome"
                @input="atualizarCodigoIbge"
                :disabled="camposDesabilitado || !this.item.uf"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="foneInput"
                label="Fone"
                v-model="item.fone"
                @input="aplicarMascaraTelefone"
                maxlength="15"
                :disabled="camposDesabilitado"
                v-numbers-only
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                label="Email"
                ref="emailInput"
                maxlength="60"
                v-model="item.email"
                @input="item.email = $event.toLowerCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                label="Observação"
                maxlength="200"
                v-model="item.observacao"
                @input="item.observacao = $event.toUpperCase()"
                :disabled="camposDesabilitado"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                ref="regimeInput"
                label="Regime"
                v-model="item.regime"
                :items="regimeitems"
                item-text="description"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                ref="averbacaoApiInput"
                label="Empresa Seguro"
                v-model="item.averbacaoApi"
                :items="averbacaoApiitems"
                item-text="description"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox label="Ativo" v-model="item.ativo"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelar"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvar"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCertificado" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Certificado</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field label="Código" v-model="item.id" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                label="Razão Social"
                v-model="item.razaoSocial"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                label="CNPJ"
                v-model="item.cpfCnpj"
                @input="formatarCnpj(item.cpfCnpj)"
                maxlength="18"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                label="Senha Certificado"
                v-model="item.certificadoSenha"
                :append-icon="showPassCert ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassCert = !showPassCert"
                :type="showPassCert ? 'text' : 'password'"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="9">
              <v-file-input
                v-model="certificadoFile"
                label="Clique para Carregar Certificado (.pfx)"
                accept=".pfx"
                @change="onCertificadoFileChange"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarCertificado"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarCertificado"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUsuario" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Usuário</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Descricao"
                ref="descricaoInput"
                v-model="usuario.descricao"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                ref="permissoesInput"
                label="Permissões"
                v-model="usuario.permissoes"
                :items="permissoesUsuario"
                item-text="description"
                :disabled="camposDesabilitado"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Usuário"
                ref="usuarioInput"
                v-model="usuario.usuario"
                @input="usuario.usuario = $event.toLowerCase()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Senha"
                ref="senhaInput"
                v-model="usuario.senha"
                :append-icon="showPassUsu ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassUsu = !showPassUsu"
                :type="showPassUsu ? 'text' : 'password'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarUsuario"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarUsuario"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfigAverbacao" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Configuração Averbação</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Código"
                ref="codigoConfigInput"
                v-model="config.codigo"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Usuário"
                ref="usuarioConfigInput"
                v-model="config.usuario"
                @input="config.usuario = $event.toLowerCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Senha"
                ref="senhaConfigInput"
                v-model="config.senha"
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarConfig"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarConfig"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaMotorista">
      <v-card>
        <v-card-title>
          <h4>Motorista</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-toolbar
          style="background-color: #1d4ed8; margin-left: 17px; margin-right: 17px"
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Motorista</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #1d4ed8; margin-left: 5px"
            @click="novoMotorista()"
          >
            <v-icon left> mdi-plus</v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaMotorista"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersMotorista"
              :items="listaMotoristas"
              v-model="selectedMotorista"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarMotorista(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Editar Motorista"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="deletarMotorista(item)" small>
                  <v-icon
                    :style="{ color: '#1d4ed8' }"
                    title="Remover motorista deste Tranportador"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaMotorista"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMotorista" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Motorista</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                ref="cpfMotoristaInput"
                label="CPF"
                v-model="itemMotorista.cpfFormatado"
                v-mask="'###.###.###-##'"
                maxlength="18"
                @blur="consultarCpf"
                :append-outer-icon="limparCampoIcon"
                @click:append-outer="limparDadosCompletoMotorista"
                :readonly="somenteLeituraCpf"
                :disabled="processoEmAndamento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                ref="nomeMotoristaInput"
                maxlength="60"
                label="Nome"
                v-model="itemMotorista.nome"
                :disabled="processoEmAndamento"
                @input="itemMotorista.nome = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <label>
                <input type="checkbox" v-model="itemMotorista.ativo" />
                Ativo
              </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarMotorista"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarMotorista"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaVeiculo">
      <v-card>
        <v-card-title>
          <h4>Veiculo</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-toolbar
          style="background-color: #1d4ed8; margin-left: 17px; margin-right: 17px"
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Veiculo</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #1d4ed8; margin-left: 5px"
            @click="novoVeiculo()"
          >
            <v-icon left> mdi-plus</v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>

        <v-skeleton-loader v-if="showLoaderListaVeiculo" type="table"></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersVeiculo"
              :items="listaVeiculos"
              v-model="selectedVeiculo"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarVeiculo(item)" small>
                  <v-icon :style="{ color: '#1d4ed8' }" title="Editar Veiculo"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="deletarVeiculo(item)" small>
                  <v-icon
                    :style="{ color: '#1d4ed8' }"
                    title="Remover veiculo deste Tranportador"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaVeiculo"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVeiculo" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Veiculo</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                ref="placaInput"
                label="Placa"
                v-model="itemVeiculo.placa"
                maxlength="8"
                @blur="consultarPlaca"
                :append-outer-icon="limparCampoIcon"
                @click:append-outer="limparDadosCompletoVeiculo"
                :readonly="somenteLeituraPlaca"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.placa = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="renavamInput"
                maxlength="11"
                label="Renavam"
                v-model="itemVeiculo.renavam"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.renavam = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="taraInput"
                maxlength="6"
                label="Tara"
                v-model="itemVeiculo.tara"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.tara = $event.toUpperCase()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                ref="capKgInput"
                maxlength="6"
                label="Capacidade"
                v-model="itemVeiculo.capKg"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.capKg = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoRodadoInput"
                label="Tipo Rodado"
                v-model="itemVeiculo.tipoRodado"
                :items="listaTipoRodado"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoCarroceriaInput"
                label="Tipo Carroceira"
                v-model="itemVeiculo.tipoCarroceria"
                :items="listaTipoCarroceria"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoVeiculoInput"
                label="Tipo Veiculo"
                v-model="itemVeiculo.tipoVeiculo"
                :items="listaTipoVeiculo"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="rntrcInput"
                maxlength="8"
                label="RNTRC"
                v-model="itemVeiculo.rntrc"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.rntrc = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-select
                ref="ufLicenciamentoInput"
                v-model="itemVeiculo.ufLicenciamento"
                :items="estados"
                label="UF"
                item-text="sigla"
                item-value="sigla"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <label>
                <input type="checkbox" v-model="itemVeiculo.ativo" />
                Ativo
              </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarVeiculo"
            style="background-color: #1d4ed8; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarVeiculo"
            style="background-color: #1d4ed8; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseWidget from "../components/BaseWidget";
import ShowMessage from "../components/ShowMessage";
import Transportador from "../service/transportador";
import Logradouro from "../service/logradouros";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Transportador",
  components: {
    BaseWidget,
    ShowMessage,
  },
  directives: {
    "numbers-only": {
      bind(el) {
        el.addEventListener("input", function (event) {
          const inputValue = event.target.value;
          event.target.value = inputValue.replace(/\D/g, "");
        });
      },
    },
  },
  data() {
    return {
      mensagem: "",
      dialogCadastro: false,
      dialogCertificado: false,
      dialogUsuario: false,
      dialogConfigAverbacao: false,
      dialogListaMotorista: false,
      dialogListaVeiculo: false,
      dialogMotorista: false,
      dialogVeiculo: false,
      certificadoFile: null,
      camposDesabilitado: false,
      showPassCert: false,
      showPassUsu: false,
      limparCampoIcon: "mdi-close-circle",
      somenteLeituraCpf: false,
      somenteLeituraPlaca: false,
      processoEmAndamento: false,
      headers: [
        { align: "start", sortable: false },
        { text: "Código", align: "left", value: "id" },
        { text: "Razão Social", align: "left", value: "razaoSocial" },
        { text: "Nome Fantasia", align: "left", value: "nomeFantasia" },
        { text: "Cnpj", value: "cpfCnpj", align: "left" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Pré-Cadastro", value: "preCadastro", align: "center" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersMotorista: [
        { align: "start", sortable: false },
        { text: "Código", align: "left", value: "id" },
        { text: "Nome", align: "left", value: "nome" },
        { text: "CPF", value: "cpfFormatado", align: "left" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersVeiculo: [
        { align: "start", sortable: false },
        { text: "Código", align: "center", value: "id" },
        { text: "Placa", align: "center", value: "placa" },
        { text: "Renavam", align: "center", value: "renavam" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      estados: [],
      municipios: [],
      selectedUF: null,
      selectedMunicipio: null,
      listaTransportadores: [],
      item: {
        razaoSocial: "",
        nomeFantasia: "",
        cpfCnpj: "",
        inscricaoEstadual: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        codigoIbge: null,
        municipio: "",
        cep: "",
        uf: "",
        fone: "",
        email: "",
        observacao: "",
        regime: 1,
        averbacaoApi: 0,
        ativo: true,
        certificadoSenha: "",
        preCadastro: false,
      },
      listaMotoristas: [],
      itemMotorista: {
        id: 0,
        idParticipante: 0,
        idMotorista: 0,
        nome: "",
        cpf: "",
        cpfFormatado: "",
        ativo: true,
      },
      listaVeiculos: [],
      itemVeiculo: {
        id: 0,
        idParticipante: 0,
        idVeiculo: 0,
        placa: "",
        tipoVeiculo: 0,
        renavam: "",
        tara: "",
        capKg: "",
        ufLicenciamento: "",
        rntrc: "",
        tipoRodado: 0,
        tipoCarroceria: 0,
        ativo: true,
      },
      selectedMotorista: [],
      selectedVeiculo: [],
      usuario: {
        idTransportador: 0,
        descricao: "",
        usuario: "",
        senha: "",
        permissoes: "",
        cpf: "",
      },
      cpfMotorista: {
        usuario: "",
        cpf: "",
        ativo: true,
      },
      config: {
        averbacaoApi: 0,
        idTransportador: 0,
        codigo: "",
        usuario: "",
        senha: "",
      },
      permissoesUsuario: [
        { value: "usuario", description: "USUARIO" },
        { value: "usuario_mdfe_encerramento", description: "ENCERRAR MDFE" },
        // { value: "motorista", description: "MOTORISTA" },
      ],
      regimeitems: [
        { value: 0, description: "SIMPLES NACIONAL" },
        { value: 1, description: "LUCRO PRESUMIDO" },
        { value: 2, description: "NORMAL" },
      ],
      averbacaoApiitems: [
        { value: 0, description: "NENHUM" },
        { value: 1, description: "ATM" },
        { value: 2, description: "SMART HUB" },
        { value: 3, description: "PORTO SEGURO" },
      ],
      selected: [],
      singleSelect: false,
      listaPesquisa: [
        { value: "ID", description: "CODIGO" },
        { value: "RAZAO_SOCIAL", description: "RAZAO SOCIAL" },
        { value: "CNPJ", description: "CNPJ" },
        { value: "FANTASIA", description: "NOME FANTASIA" },
        { value: "PRE_CADASTRO", description: "PRE CADASTRO" },
      ],
      listaStatus: [
        { value: "TODOS", description: "TODOS" },
        { value: "ATIVO", description: "ATIVO" },
        { value: "INATIVO", description: "INATIVO" },
      ],
      listaTipoVeiculo: [
        { value: 0, description: "PRINCIPAL" },
        { value: 1, description: "REBOQUE" },
      ],
      listaTipoRodado: [
        { value: 1, description: "TRUCK" },
        { value: 2, description: "TOCO" },
        { value: 3, description: "CAVALO" },
        { value: 4, description: "VAN" },
        { value: 5, description: "UTILITARIO" },
        { value: 6, description: "OUTROS" },
      ],
      listaTipoCarroceria: [
        { value: 0, description: "NAO APLICAVEL " },
        { value: 1, description: "ABERTA" },
        { value: 2, description: "FECHADA/BAU" },
        { value: 3, description: "GRANELERA" },
        { value: 4, description: "PORTA CONTAINER" },
        { value: 5, description: "SIDER" },
      ],
      pesquisaSelecionado: "",
      tfPesquisa: "",
      statusSelecionado: "",
      selecaoFiltro: {
        campo: "",
        valor: "",
      },
      showLoader: false,
      showLoaderListaMotorista: false,
      showLoaderListaVeiculo: false,
      filtroRelatorio: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted2: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      getColor(ativo) {
        if (ativo == true) {
          return "#068206";
        } else {
          return "#F44336";
        }
      },
      getColorPreCadastro(preCdastro) {
        if (preCdastro == true) {
          return "#F44336";
        } else {
          return "#068206";
        }
      },
      menu1: false,
      menu2: false,
      singleExpand: true,
    };
  },
  computed: {
    itens() {
      return {
        selectedUF: this.selectedUF,
        selectedCidade: this.selectedCidade,
      };
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
    pesquisaSelecionadoDescription() {
      const selectedValue = this.pesquisaSelecionado;
      const selectedOption = this.listaPesquisa.find(
        (item) => item.value === selectedValue
      );
      return selectedOption ? selectedOption.description : "";
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },

  mounted() {
    this.statusSelecionado = "TODOS";
  },

  created() {
    this.loadEstados();
  },

  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),

    filtrar() {
      this.selected = [];
      this.showLoader = true;

      const tipoFiltro = this.pesquisaSelecionado;
      const valorFiltro = this.tfPesquisa;
      const status = this.statusSelecionado;
      const tipo = "TRANSPORTADOR";

      if (tipoFiltro == "") {
        this.$refs.message.showMsg("Atenção!", "Informe o tipo do filtro");
        this.showLoader = false;
        return;
      }

      if (tipoFiltro != "PRE_CADASTRO" && !valorFiltro) {
        this.$refs.message.showMsg("Atenção!", "Informe o valor do filtro");
        this.showLoader = false;
        return;
      }

      if (status < 0) {
        this.$refs.message.showMsg("Atenção!", "Informe a Situação");
        this.showLoader = false;
        return;
      }

      const parsedValue = parseInt(valorFiltro, 10);

      if (
        this.pesquisaSelecionado === "ID" &&
        isNaN(parsedValue) &&
        !Number.isInteger(parsedValue)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Para este de tipo de pesquisa é necessário digitar valores inteiro."
        );
        this.showLoader = false;
        return;
      }

      Transportador.getTransportadores(tipoFiltro, valorFiltro, status, tipo)
        .then((response) => {
          this.listaTransportadores = response.data.map((item) => {
            const cnpjFormatado = item.cpfCnpj.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );

            const cepFormatado = item.endereco.cep
              ? item.endereco.cep.replace(/^(\d{5})(\d{3})$/, "$1-$2")
              : "";

            let telefoneFormatado = "";
            if (item.endereco && item.endereco.fone) {
              let telefone = item.endereco.fone.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

              if (telefone.length === 10) {
                telefoneFormatado = telefone.replace(
                  /^(\d{2})(\d{4})(\d{4})$/,
                  "($1) $2-$3"
                ); // Formato para telefone fixo (XX) XXXX-XXXX
              } else if (telefone.length === 11) {
                telefoneFormatado = telefone.replace(
                  /^(\d{2})(\d{5})(\d{4})$/,
                  "($1) $2-$3"
                ); // Formato para celular (XX) XXXXX-XXXX
              } else {
                // Caso não seja telefone fixo nem celular, deixe sem formatação
                telefoneFormatado = telefone;
              }
            }

            return {
              id: item.id,
              razaoSocial: item.razaoSocial,
              cpfCnpj: cnpjFormatado,
              inscricaoEstadual: item.inscricaoEstadual,
              nomeFantasia: item.nomeFantasia,
              email: item.email,
              logradouro: item.endereco.logradouro,
              numero: item.endereco.numero,
              complemento: item.endereco.complemento,
              bairro: item.endereco.bairro,
              codigoIbge: item.endereco.codigoIbge,
              municipio: item.endereco.municipio,
              cep: cepFormatado,
              uf: item.endereco.uf,
              fone: telefoneFormatado,
              observacao: item.observacao,
              regime: item.regime,
              averbacaoApi: item.averbacaoApi,
              ativo: item.ativo,
              preCadastro: item.preCadastro,
              certificadoSenha: "",
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarTransportador(item),
                },
                {
                  icon: "mdi-shield-key",
                  callback: () => this.certificadoTransportador(item),
                },
                {
                  icon: "mdi-account",
                  callback: () => this.usuarioTransportador(item),
                },
                {
                  icon: "mdi-account-key",
                  callback: () => this.transportadorConfig(item),
                },
                {
                  icon: "mdi-account-check",
                  callback: () => this.liberarPreCadastro(item),
                },
                {
                  icon: "mdi-steering",
                  callback: () => this.listarMotoristas(item),
                },
                {
                  icon: "mdi-car",
                  callback: () => this.listarVeiculos(item),
                },
              ],
            };
          });
          this.showLoader = false;
        })
        .catch((error) => {
          this.listaTransportadores = [];
          this.showLoader = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar o transportador: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar o transportador: " + error.message
            );
          }
        });
    },

    novoTransportador() {
      this.item = {
        id: 0,
        razaoSocial: "",
        nomeFantasia: "",
        cpfCnpj: "",
        inscricaoEstadual: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        codigoIbge: null,
        municipio: "",
        cep: "",
        uf: "",
        fone: "",
        email: "",
        observacao: "",
        regime: null,
        averbacaoApi: null,
        ativo: true,
        certificadoSenha: "",
        tipoParticipante: "TRANSPORTADOR",
        preCadastro: false,
      };

      this.dialogCadastro = true;
    },

    novoMotorista() {
      this.itemMotorista = {
        id: 0,
        idParticipante: this.item.id,
        idMotorista: 0,
        nome: "",
        cpf: "",
        cpfFormatado: "",
        ativo: true,
      };

      this.somenteLeituraCpf = false;
      this.dialogMotorista = true;
    },

    novoVeiculo() {
      this.itemVeiculo = {
        id: 0,
        idParticipante: this.item.id,
        idVeiculo: 0,
        placa: "",
        renavam: "",
        tipoCarroceria: 0,
        tara: "",
        capKg: "",
        ufLicenciamento: "",
        rntrc: "",
        tipoRodado: 0,
        ativo: true,
      };

      this.somenteLeituraPlaca = false;
      this.dialogVeiculo = true;
    },

    criarItemTransportador(transportador) {
      return {
        id: transportador.id,
        razaoSocial: transportador.razaoSocial,
        nomeFantasia: transportador.nomeFantasia,
        cpfCnpj: transportador.cpfCnpj,
        inscricaoEstadual: transportador.inscricaoEstadual,
        logradouro: transportador.logradouro,
        numero: transportador.numero,
        complemento: transportador.complemento,
        bairro: transportador.bairro,
        codigoIbge: transportador.codigoIbge,
        municipio: transportador.municipio,
        cep: transportador.cep,
        uf: transportador.uf,
        fone: transportador.fone,
        email: transportador.email,
        observacao: transportador.observacao,
        regime: transportador.regime,
        averbacaoApi: transportador.averbacaoApi,
        ativo: transportador.ativo,
        certificadoSenha: "",
      };
    },

    editarTransportador(transportador) {
      this.item = this.criarItemTransportador(transportador);
      this.loadMunicipios();
      this.dialogCadastro = true;
    },

    certificadoTransportador(transportador) {
      this.item = this.criarItemTransportador(transportador);

      this.certificadoFile = "";
      this.dialogCertificado = true;
    },

    usuarioTransportador(transportador) {
      this.usuario = this.criarItemUsuario(transportador);

      this.dialogUsuario = true;
    },

    transportadorConfig(transportador) {
      this.config = this.criarItemConfig(transportador);

      if (transportador.averbacaoApi <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Transportador não possui nenhuma Empresa de Seguro!"
        );

        return;
      }

      this.dialogConfigAverbacao = true;
    },

    criarItemUsuario(transportador) {
      return {
        idTransportador: transportador.id,
        descricao: transportador.razaoSocial,
        usuario: "",
        senha: "",
      };
    },

    async criarItemConfig(transportador) {
      try {
        const response = await Transportador.getConfigAverbacao(transportador.id);
        this.config = {
          idTransportador: transportador.id,
          averbacaoApi: transportador.averbacaoApi,
          usuario: response.data.usuario,
          senha: response.data.senha,
          codigo: response.data.codigo,
        };
      } catch (error) {
        this.config = {
          idTransportador: transportador.id,
          averbacaoApi: transportador.averbacaoApi,
          usuario: null,
          senha: null,
          codigo: null,
        };
      }
    },

    listarMotoristas(transportador) {
      this.item = transportador;
      this.carregarMotoristas(transportador.id);
      this.dialogListaMotorista = true;
    },

    carregarMotoristas(idTransportador) {
      this.selectedMotorista = [];
      this.showLoaderListaMotorista = true;
      this.listaMotoristas = [];

      Transportador.getMotoristas(idTransportador)
        .then((response) => {
          this.listaMotoristas = response.data.map((participanteMotorista) => {
            return {
              id: participanteMotorista.id,
              idParticipante: participanteMotorista.participante.id,
              idMotorista: participanteMotorista.motorista.id,
              nome: participanteMotorista.motorista.nome,
              cpf: participanteMotorista.motorista.cpf,
              ativo: participanteMotorista.motorista.ativo,
              cpfFormatado: this.formatarCpfCnpj(participanteMotorista.motorista.cpf),
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarMotorista(participanteMotorista),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.deletarMotorista(participanteMotorista),
                },
              ],
            };
          });

          this.showLoaderListaMotorista = false;
        })
        .catch((error) => {
          this.listaMotoristas = [];
          this.showLoaderListaMotorista = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Motoristas: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Motoristas: " + error.message
            );
          }
        });
    },

    listarVeiculos(transportador) {
      this.item = transportador;
      this.carregarVeiculos(transportador.id);
      this.dialogListaVeiculo = true;
    },

    carregarVeiculos(idTransportador) {
      this.selectedVeiculo = [];
      this.showLoaderListaVeiculo = true;
      this.listaVeiculos = [];

      Transportador.getVeiculos(idTransportador)
        .then((response) => {
          this.listaVeiculos = response.data.map((participanteVeiculo) => {
            return {
              id: participanteVeiculo.id,
              idParticipante: participanteVeiculo.participante.id,
              idVeiculo: participanteVeiculo.veiculo.id,
              placa: participanteVeiculo.veiculo.placa,
              tipoVeiculo: participanteVeiculo.veiculo.tipoVeiculo,
              renavam: participanteVeiculo.veiculo.renavam,
              tara: participanteVeiculo.veiculo.tara,
              capKg: participanteVeiculo.veiculo.capKg,
              ufLicenciamento: participanteVeiculo.veiculo.ufLicenciamento,
              rntrc: participanteVeiculo.veiculo.rntrc,
              tipoRodado: participanteVeiculo.veiculo.tipoRodado,
              tipoCarroceria: participanteVeiculo.veiculo.tipoCarroceria,
              ativo: participanteVeiculo.veiculo.ativo,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarVeiculo(participanteVeiculo),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.deletarVeiculo(participanteVeiculo),
                },
              ],
            };
          });

          this.showLoaderListaVeiculo = false;
        })
        .catch((error) => {
          this.listaVeiculos = [];
          this.showLoaderListaVeiculo = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Veiculos: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Veiculos: " + error.message
            );
          }
        });
    },

    liberarPreCadastro(transportador) {
      if (!transportador.preCadastro) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação permitida apenas para pré-cadastro!"
        );

        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja liberar este cadastro?",
        (resposta) => {
          if (resposta) {
            // this.setLoading(true, "Liberando pré-cadastro...");

            const finishLoading = () => {
              this.setLoading(false);
            };

            Transportador.liberarPreCadastro(transportador.id)
              .then((response) => {
                console.log("Pré-Cadastro liberado com sucesso!", response);
                this.snackSuccess("Pré-Cadastro liberado com sucesso");
                this.filtrar();
              })
              .catch((error) => {
                if (error.response) {
                  this.$refs.message.showMsg(
                    "ERROR!",
                    "Erro ao liberar pré-cadastro: " + error.response.data.mensagem
                  );
                } else {
                  this.$refs.message.showMsg(
                    "ERROR!",
                    "Erro ao liberar pré-cadastro: " + error.message
                  );
                }
              })
              .finally(finishLoading);
          }

          this.setLoading(false);
        }
      );
    },

    clickBtnSalvarMotorista() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          this.processoEmAndamento = true;

          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioMotorista()) {
              if (this.itemMotorista.id === 0) {
                Transportador.insertMotorista(this.itemMotorista)
                  .then((response) => {
                    console.log("Motorista criada com sucesso!", response);
                    this.snackSuccess("Motorista criada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogMotorista = false;
                    this.carregarMotoristas(this.itemMotorista.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Motorista: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Motorista: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateMotorista(this.itemMotorista.id, this.itemMotorista)
                  .then((response) => {
                    console.log("Motorista atualizada com sucesso!", response);
                    this.snackSuccess("Motorista atualizada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogMotorista = false;
                    this.carregarMotoristas(this.itemMotorista.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Motorista: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Motorista: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioMotorista() {
      this.itemMotorista.nome = this.itemMotorista.nome.trim();
      this.itemMotorista.cpf = this.itemMotorista.cpfFormatado.replace(/\D/g, "");

      if (!this.itemMotorista.cpf) {
        this.$refs.message.showMsg("Atenção!", "Campo CPF é obrigatório");
        return false;
      }

      if (!this.itemMotorista.nome) {
        this.$refs.nomeMotoristaInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Nome é obrigatório");
        return false;
      }

      if (!this.validarCPF(this.itemMotorista.cpf)) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg("Atenção!", "CPF inválido! Favor tente novamente.");
        return false;
      }

      return true;
    },

    editarMotorista(participanteMotorista) {
      this.itemMotorista = participanteMotorista;

      this.dialogMotorista = true;
    },

    deletarMotorista(participanteMotorista) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja remover o Motorista do Transportador?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Removendo...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (participanteMotorista.id > 0) {
              Transportador.deletarMotorista(participanteMotorista.id)
                .then((response) => {
                  console.log("Remoção efetuada com sucesso!", response);
                  this.snackSuccess("Remoção efetuada com sucesso!!");
                  this.carregarMotoristas(participanteMotorista.idParticipante);
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao remover o motorista: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir o motorista: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg("ATENÇÃO!", "Motorista não encontrada!");
            }
          }
          this.setLoading(false);
        }
      );
    },

    clickBtnSalvarVeiculo() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          this.processoEmAndamento = true;

          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioVeiculo()) {
              if (this.itemVeiculo.id === 0) {
                Transportador.insertVeiculo(this.itemVeiculo)
                  .then((response) => {
                    console.log("Veiculo criada com sucesso!", response);
                    this.snackSuccess("Veiculo criada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogVeiculo = false;
                    this.carregarVeiculos(this.itemVeiculo.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Veiculo: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Veiculo: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateVeiculo(this.itemVeiculo.id, this.itemVeiculo)
                  .then((response) => {
                    console.log("Veiculo atualizada com sucesso!", response);
                    this.snackSuccess("Veiculo atualizada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogVeiculo = false;
                    this.carregarVeiculos(this.itemVeiculo.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Veiculo: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Veiculo: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioVeiculo() {
      this.itemVeiculo.placa = this.itemVeiculo.placa.trim();

      if (!this.itemVeiculo.placa) {
        this.$refs.message.showMsg("Atenção!", "Campo Placa é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.renavam) {
        this.$refs.message.showMsg("Atenção!", "Campo Renavam é obrigatório");
        return false;
      }

      if (
        this.itemVeiculo.renavam.includes(" ") ||
        this.itemVeiculo.renavam.length < 9 ||
        this.itemVeiculo.renavam.length > 11
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Valor inválido para o campo Renavam. Valor permitido entre 9 a 11 caracteres sem espaços."
        );
        return false;
      }

      if (!this.itemVeiculo.tara) {
        this.$refs.message.showMsg("Atenção!", "Campo tara é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.capKg) {
        this.$refs.message.showMsg("Atenção!", "Campo capKg é obrigatório");
        return false;
      }

      if (this.itemVeiculo.tipoRodado <= 0) {
        this.$refs.message.showMsg("Atenção!", "Campo Tipo Rodada é obrigatório");
        return false;
      }

      if (this.itemVeiculo.tipoCarroceria < 0) {
        this.$refs.message.showMsg("Atenção!", "Campo Tipo Carroceria é obrigatório");
        return false;
      }

      if (this.itemVeiculo.tipoVeiculo < 0) {
        this.$refs.message.showMsg("Atenção!", "Campo Tipo Veiculo é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.rntrc) {
        this.$refs.message.showMsg("Atenção!", "Campo Tipo Rodada é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.ufLicenciamento) {
        this.$refs.message.showMsg("Atenção!", "Campo UF Licenciamento é obrigatório");
        return false;
      }

      return true;
    },

    editarVeiculo(participanteVeiculo) {
      this.itemVeiculo = participanteVeiculo;

      this.dialogVeiculo = true;
    },

    deletarVeiculo(participanteVeiculo) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja remover o Veiculo do Transportador?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Removendo...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (participanteVeiculo.id > 0) {
              Transportador.deletarVeiculo(participanteVeiculo.id)
                .then((response) => {
                  console.log("Remoção efetuada com sucesso!", response);
                  this.snackSuccess("Remoção efetuada com sucesso!!");
                  this.carregarVeiculos(participanteVeiculo.idParticipante);
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao remover o veiculo: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir o veiculo: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg("ATENÇÃO!", "Veiculo não encontrada!");
            }
          }
          this.setLoading(false);
        }
      );
    },

    loadEstados() {
      Logradouro.getEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os estados: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os estados: " + error.message
            );
          }
        });
    },

    loadMunicipios() {
      if (this.item.uf) {
        Logradouro.getCidades(this.item.uf)
          .then((response) => {
            this.municipios = response.data;
          })
          .catch((error) => {
            if (error.response) {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades: " + error.response.data.mensagem
              );
            } else {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades: " + error.message
              );
            }
          });
      }
    },

    atualizarCodigoIbge() {
      const cidadeSelecionada = this.municipios.find(
        (cidade) => cidade.nome === this.item.municipio
      );

      if (cidadeSelecionada) {
        this.item.codigoIbge = cidadeSelecionada.codigoIbge;
      }
    },

    clickBtnSalvar() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");

            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.validarDadosFormulario()) {
              if (this.item.id === 0) {
                Transportador.insertTransportador(this.item)
                  .then((response) => {
                    console.log("Transportador criado com sucesso!", response);
                    this.snackSuccess("Transportador criado com sucesso!");
                    this.dialogCadastro = false;
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o transportador: " + error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o transportador: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateTransportador(this.item.id, this.item)
                  .then((response) => {
                    console.log("Transportador atualizado com sucesso!", response);
                    this.snackSuccess("Transportador atualizado com sucesso!");
                    this.dialogCadastro = false;
                    this.filtrar();
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o transportador: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o transportador: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            }

            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormulario() {
      this.item.razaoSocial = this.item.razaoSocial.trim();
      this.item.nomeFantasia = this.item.nomeFantasia.trim();
      this.item.inscricaoEstadual = this.item.inscricaoEstadual.trim();
      this.item.logradouro = this.item.logradouro.trim();
      this.item.numero = this.item.numero.trim();
      this.item.bairro = this.item.bairro.trim();
      this.item.email = this.item.email.trim();
      this.item.complemento = this.item.complemento.trim();

      if (!this.item.razaoSocial) {
        this.$refs.razaoSocialInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Razão Social é obrigatório");
        return false;
      }

      if (!this.item.cpfCnpj) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo CNPJ é obrigatório");
        return false;
      }

      if (!this.validarCNPJ(this.item.cpfCnpj)) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg("Atenção!", "CNPJ inválido! Favor tente novamente.");
        return false;
      }

      if (!this.item.nomeFantasia) {
        this.$refs.nomeFantasiaInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Nome Fantasia é obrigatório");
        return false;
      }

      if (!this.item.inscricaoEstadual) {
        this.$refs.inscricaoEstadualInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Inscrição Estadual é obrigatório");
        return false;
      }

      if (!this.item.logradouro) {
        this.$refs.logradouroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Logradouro é obrigatório");
        return false;
      }

      if (!this.item.numero) {
        this.$refs.numeroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Número é obrigatório");
        return false;
      }

      if (!this.item.cep) {
        this.$refs.cepInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo CEP é obrigatório");
        return false;
      }

      if (!this.item.bairro) {
        this.$refs.bairroInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Bairro é obrigatório");
        return false;
      }

      if (this.item.codigoIbge <= 0) {
        this.$refs.codigoIbgeInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Código do IBGE é obrigatório");
        return false;
      }

      if (!this.item.municipio) {
        this.$refs.municipioInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Munícipio é obrigatório");
        return false;
      }

      if (!this.item.uf) {
        this.$refs.ufInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo UF é obrigatório");
        return false;
      }

      if (!this.item.fone) {
        this.$refs.foneInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Fone é obrigatório");
        return false;
      }

      if (!this.item.email) {
        this.$refs.emailInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo E-mail é obrigatório");
        return false;
      }

      if (this.item.regime < 0) {
        this.$refs.regimeInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Regime é obrigatório");
        return false;
      }

      if (this.item.averbacaoApi < 0) {
        this.$refs.averbacaoApiInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Empresa Seguro é obrigatório");
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.razaoSocial)) {
        this.$refs.razaoSocialInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.nomeFantasia)) {
        this.$refs.nomeFantasiaInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.inscricaoEstadual)) {
        this.$refs.inscricaoEstadualInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Inscrição Estadual deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.logradouro)) {
        this.$refs.logradouroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.numero)) {
        this.$refs.numeroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Numero deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.bairro)) {
        this.$refs.bairroInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.complemento)) {
        this.$refs.complementoInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Complemento deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.item.observacao)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Observação deve conter apenas caracteres alfanuméricos"
        );
        return false;
      }

      if (!this.item.complemento) {
        this.item.complemento = "SEM COMP";
      }

      this.mensagem = "";
      return true;
    },

    validarDadosFormularioUsuario() {
      if (!this.usuario.idTransportador) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Não foi possível recuperar o Id Transportador"
        );
        return false;
      }

      if (!this.usuario.descricao) {
        this.$refs.descricaoInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Descrição é obrigatório");
        return false;
      }

      if (!this.usuario.permissoes) {
        this.$refs.permissoesInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Permissão é obrigatório");
        return false;
      }

      if (!this.usuario.usuario) {
        this.$refs.usuarioInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Usuário é obrigatório");
        return false;
      }

      if (!this.usuario.senha) {
        this.$refs.senhaInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Senha é obrigatório");
        return false;
      }

      return true;
    },

    validarDadosFormularioConfig() {
      if (!this.config.idTransportador) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Não foi possível recuperar o Id Transportador"
        );
        return false;
      }

      if (!this.config.codigo) {
        this.$refs.codigoConfigInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Código é obrigatório");
        return false;
      }

      if (!this.config.usuario) {
        this.$refs.usuarioConfigInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Usuário é obrigatório");
        return false;
      }

      if (!this.config.senha) {
        this.$refs.senhaConfigInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Senha é obrigatório");
        return false;
      }

      return true;
    },

    clickBtnCancelar() {
      this.dialogCadastro = false;
    },

    clickBtnCancelarMotorista() {
      this.dialogMotorista = false;
    },

    clickBtnCancelarListaMotorista() {
      this.dialogListaMotorista = false;
    },

    clickBtnCancelarVeiculo() {
      this.dialogVeiculo = false;
    },

    clickBtnCancelarListaVeiculo() {
      this.dialogListaVeiculo = false;
    },

    clickBtnSalvarCertificado() {
      const cpfCnpj = this.item.cpfCnpj.toString().replace(/\D/g, "");
      const certificadoSenha = this.item.certificadoSenha;
      const arquivo = this.certificadoFile;

      if (!certificadoSenha) {
        this.$refs.message.showMsg("Atenção!", "Campo Senha do Certificado obrigatório");
        return;
      }

      if (!arquivo) {
        this.$refs.message.showMsg("Atenção!", "Campo Certificado obrigatório");
        return;
      }

      Transportador.salvarCertificado(arquivo, cpfCnpj, certificadoSenha)
        .then(() => {
          this.snackSuccess("Certificado salvo com sucesso!");
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao salvar o certificado: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao salvar o certificado: " + error.message
            );
          }
        })
        .finally(() => {
          this.dialogCertificado = false;
        });
    },

    clickBtnCancelarCertificado() {
      this.dialogCertificado = false;
    },

    clickBtnSalvarUsuario() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar este Usuario?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");

            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.validarDadosFormularioUsuario()) {
              Transportador.salvarUsuario(this.usuario)
                .then((response) => {
                  console.log("Usuario criado com sucesso!", response);
                  this.snackSuccess("Usuario criado com sucesso!");
                  // this.salvarCpfMotorista(this.usuario);
                  this.dialogUsuario = false;
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao criar o usuario: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao criar o usuario: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            }

            this.setLoading(false);
          }
        }
      );
    },

    salvarCpfMotorista(usuario) {
      if (usuario.permissoes == "motorista") {
        this.cpfMotorista.usuario = this.usuario.usuario;
        this.cpfMotorista.cpf = this.usuario.cpf;

        Transportador.salvarCpfMotorista(this.cpfMotorista)
          .then((response) => {
            console.log("Cpf motorista criado com sucesso!", response);
          })
          .catch((error) => {
            if (error.response) {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao salvar cpf motorista: " + error.response.data.mensagem
              );
            } else {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao salvar cpf motorista: " + error.message
              );
            }
          });
      }
    },

    clickBtnCancelarUsuario() {
      this.dialogUsuario = false;
    },

    clickBtnSalvarConfig() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar estas Informações?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");

            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.validarDadosFormularioConfig()) {
              Transportador.salvarConfigAverbacao(
                this.config.idTransportador,
                this.config
              )
                .then((response) => {
                  console.log("Configuração salva com sucesso!", response);
                  this.snackSuccess("Configuração salva com sucesso!");
                  this.dialogConfigAverbacao = false;
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao salvar configuração: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao salvar configuração: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            }

            this.setLoading(false);
          }
        }
      );
    },

    clickBtnCancelarConfig() {
      this.dialogConfigAverbacao = false;
    },

    onCertificadoFileChange() {
      console.log("Certificado selecionado: " + this.certificadoFile.name);
    },

    formatarCnpj(cnpj) {
      if (!cnpj) return "";

      // Remove todos os caracteres não numéricos
      cnpj = cnpj.toString().replace(/\D/g, "");

      // Garante um CNPJ com no máximo 14 dígitos
      cnpj = cnpj.substr(0, 14);

      // Aplica a máscara: XX.XXX.XXX/XXXX-XX
      this.item.cpfCnpj = `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(
        5,
        3
      )}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`;
    },

    aplicarMascaraTelefone() {
      this.item.fone = this.formatarTelefone(this.item.fone);
    },
    formatarTelefone(telefone) {
      if (!telefone) return "";

      // Remove todos os caracteres não numéricos
      const numerosTelefone = telefone.replace(/\D/g, "");

      // Verifique se é celular (9 dígitos) ou telefone fixo (8 dígitos)
      const isCelular = numerosTelefone.length === 11;

      // Verifique o limite de caracteres para telefone (10 para fixo, 11 para celular)
      const limiteCaracteres = isCelular ? 11 : 10;
      const telefoneFormatado = numerosTelefone.substr(0, limiteCaracteres);

      // Aplica a máscara: (XX) XXXXX-XXXX ou (XX) XXXX-XXXX
      return telefoneFormatado.replace(
        isCelular ? /^(\d{2})(\d{5})(\d{4})/ : /^(\d{2})(\d{4})(\d{4})/,
        isCelular ? "($1) $2-$3" : "($1) $2-$3"
      );
    },

    validarCNPJ(cnpj) {
      // Remove caracteres não numéricos
      cnpj = cnpj.replace(/\D/g, "");

      // Valida o tamanho do CNPJ
      if (cnpj.length !== 14) {
        return false;
      }

      // Valida dígitos verificadores
      const pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const digito1 = cnpj.charAt(12);
      const digito2 = cnpj.charAt(13);
      const calcularDigito = (cnpj, pesos) => {
        const soma = cnpj
          .split("")
          .slice(0, pesos.length)
          .map((d, i) => parseInt(d, 10) * pesos[i])
          .reduce((a, b) => a + b, 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
      };

      const valido =
        parseInt(digito1, 10) === calcularDigito(cnpj, pesos1) &&
        parseInt(digito2, 10) === calcularDigito(cnpj, pesos2);

      return valido;
    },

    validarCPF(cpf) {
      // Remove caracteres não numéricos
      cpf = cpf.replace(/\D/g, "");

      // Valida o tamanho do CPF
      if (cpf.length !== 11) {
        return false;
      }

      // Valida dígitos verificadores
      const pesos1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
      const pesos2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
      const digito1 = cpf.charAt(9);
      const digito2 = cpf.charAt(10);
      const calcularDigito = (cpf, pesos) => {
        const soma = cpf
          .split("")
          .slice(0, pesos.length)
          .map((d, i) => parseInt(d, 10) * pesos[i])
          .reduce((a, b) => a + b, 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
      };

      const valido =
        parseInt(digito1, 10) === calcularDigito(cpf, pesos1) &&
        parseInt(digito2, 10) === calcularDigito(cpf, pesos2);

      return valido;
    },

    consultarCnpj() {
      this.camposDesabilitado = true;

      if (!this.item.cpfCnpj) {
        this.camposDesabilitado = false;
        return;
      }

      if (!this.validarCNPJ(this.item.cpfCnpj)) {
        this.camposDesabilitado = false;
        this.$refs.message.showMsg("Atenção!", "CNPJ inválido! Favor tente novamente.");
        this.limparCampos();

        setTimeout(() => {
          this.$refs.cpfCnpjInput.focus();
        }, 0);

        return;
      }

      const cpfCnpj = this.item.cpfCnpj.replace(/\D/g, "");
      const tipo = "TRANSPORTADOR";

      Transportador.getCpfCnpj(cpfCnpj, tipo)
        .then((response) => {
          const data = response.data;

          const cepFormatado = data.endereco.cep
            ? data.endereco.cep.replace(/^(\d{5})(\d{3})$/, "$1-$2")
            : "";

          let telefoneFormatado = "";
          if (data.endereco && data.endereco.fone) {
            let telefone = data.endereco.fone.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

            if (telefone.length === 10) {
              telefoneFormatado = telefone.replace(
                /^(\d{2})(\d{4})(\d{4})$/,
                "($1) $2-$3"
              ); // Formato para telefone fixo (XX) XXXX-XXXX
            } else if (telefone.length === 11) {
              telefoneFormatado = telefone.replace(
                /^(\d{2})(\d{5})(\d{4})$/,
                "($1) $2-$3"
              ); // Formato para celular (XX) XXXXX-XXXX
            } else {
              // Caso não seja telefone fixo nem celular, deixe sem formatação
              telefoneFormatado = telefone;
            }
          }

          this.item.id = data.id || "";
          this.item.razaoSocial = data.razaoSocial || "";
          this.item.nomeFantasia = data.nomeFantasia || "";
          this.item.inscricaoEstadual = data.inscricaoEstadual || "";
          this.item.logradouro = data.endereco.logradouro || "";
          this.item.numero = data.endereco.numero || "";
          this.item.complemento = data.endereco.complemento || "";
          this.item.bairro = data.endereco.bairro || "";
          this.item.codigoIbge = data.endereco.codigoIbge || "";
          this.item.municipio = data.endereco.municipio || "";
          this.item.cep = cepFormatado;
          this.item.uf = data.endereco.uf || "";
          this.item.fone = telefoneFormatado;
          this.item.email = data.email || "";
          this.item.observacao = data.observacao || "";
          this.item.regime = data.regime;
          this.item.averbacaoApi = data.averbacaoApi;

          this.camposDesabilitado = false;

          setTimeout(() => {
            this.$refs.razaoSocialInput.focus();
          }, 0);
        })
        .catch(() => {
          this.camposDesabilitado = false;
          this.limparCampos(this.item.cpfCnpj);

          setTimeout(() => {
            this.$refs.razaoSocialInput.focus();
          }, 0);
        });
    },

    consultarCpf() {
      if (this.somenteLeituraCpf) {
        return;
      }

      if (!this.validarCPF(this.itemMotorista.cpfFormatado)) {
        this.$refs.message.showMsg("Atenção!", "CPF inválido! Favor tente novamente.");

        this.itemMotorista.nome = "";
        this.itemMotorista.ativo = true;
        this.somenteLeituraCpf = false;

        setTimeout(() => {
          this.$refs.cpfMotoristaInput.focus();
        }, 0);

        return;
      }

      const cpf = this.itemMotorista.cpfFormatado.replace(/\D/g, "");

      Transportador.getCpfMotorista(cpf)
        .then((response) => {
          const data = response.data;

          this.itemMotorista.idMotorista = data.id || 0;
          this.itemMotorista.nome = data.nome || "";
          this.itemMotorista.cpf = data.cpf || "";
          this.itemMotorista.ativo = data.ativo || "";
          (this.itemMotorista.cpfFormatado = this.formatarCpfCnpj(data.cpf)),
            (this.somenteLeituraCpf = true);

          setTimeout(() => {
            this.$refs.nomeMotoristaInput.focus();
          }, 0);
        })
        .catch(() => {
          this.itemMotorista.idMotorista = 0;
          this.itemMotorista.nome = "";
          this.itemMotorista.ativo = true;
          this.somenteLeituraCpf = true;

          setTimeout(() => {
            this.$refs.nomeMotoristaInput.focus();
          }, 0);
        });
    },

    consultarPlaca() {
      if (this.somenteLeituraPlaca) {
        return;
      }

      Transportador.getPlacaVeiculo(this.itemVeiculo.placa)
        .then((response) => {
          const data = response.data;

          this.itemVeiculo.idVeiculo = data.id || 0;
          this.itemVeiculo.tipoVeiculo = data.tipoVeiculo;
          this.itemVeiculo.placa = data.placa || "";
          this.itemVeiculo.renavam = data.renavam || "";
          this.itemVeiculo.tara = data.tara || "";
          this.itemVeiculo.capKg = data.capKg || "";
          this.itemVeiculo.ufLicenciamento = data.ufLicenciamento || "";
          this.itemVeiculo.rntrc = data.rntrc || "";
          this.itemVeiculo.tipoRodado = data.tipoRodado;
          this.itemVeiculo.tipoCarroceria = data.tipoCarroceria;
          this.itemVeiculo.ativo = data.ativo || "";

          this.somenteLeituraPlaca = true;

          setTimeout(() => {
            this.$refs.placaInput.focus();
          }, 0);
        })
        .catch(() => {
          this.limparDadosVeiculo();

          setTimeout(() => {
            this.$refs.renavamInput.focus();
          }, 0);
        });
    },

    limparDadosCompletoMotorista() {
      this.itemMotorista.id = 0;
      this.itemMotorista.cpf = "";
      this.itemMotorista.cpfFormatado = "";
      this.itemMotorista.nome = "";

      this.somenteLeituraCpf = false;
    },

    limparDadosCompletoVeiculo() {
      this.itemMotorista.id = 0;
      this.itemMotorista.placa = "";

      this.limparDadosVeiculo();

      this.somenteLeituraPlaca = false;
    },

    limparDadosVeiculo() {
      this.itemVeiculo.idVeiculo = 0;
      this.itemVeiculo.tipoVeiculo = 0;
      this.itemVeiculo.renavam = "";
      this.itemVeiculo.tara = "";
      this.itemVeiculo.capKg = "";
      this.itemVeiculo.ufLicenciamento = "";
      this.itemVeiculo.rntrc = "";
      this.itemVeiculo.tipoRodado = "";
      this.itemVeiculo.tipoCarroceria = "";
      this.itemVeiculo.ativo = true;
    },

    formatarCpfCnpj(doc) {
      if (!doc) return "";

      // Remove todos os caracteres não numéricos
      doc = doc.toString().replace(/\D/g, "");

      // Garante um CNPJ com no máximo 14 dígitos
      doc = doc.substr(0, 14);

      // Formatação de CNPJ: XX.XXX.XXX/XXXX-XX
      let docFormatado = `${doc.substr(0, 2)}.${doc.substr(2, 3)}.${doc.substr(
        5,
        3
      )}/${doc.substr(8, 4)}-${doc.substr(12, 2)}`;

      // Se for um CPF (11 dígitos), aplica a máscara de CPF: 000.000.000-00
      if (doc.length === 11) {
        docFormatado = doc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
      }

      return docFormatado;
    },

    aplicarMascaraCep() {
      this.item.cep = this.formatarCep(this.item.cep);
    },

    formatarCep(cep) {
      if (!cep) return "";

      // Remove todos os caracteres não numéricos
      const numerosCep = cep.replace(/\D/g, "");

      // Verifique o limite de caracteres para CEP (8 dígitos)
      const limiteCaracteres = 8;
      const cepFormatado = numerosCep.substr(0, limiteCaracteres);

      // Aplica a máscara: XXXXX-XXX
      return cepFormatado.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-treeselect__control {
  border-radius: 0;
}

.responsivo {
  position: absolute;
  top: -100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    position: relative;
    top: 0%;
  }
}
</style>
