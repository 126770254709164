<!-- YoutubePlayer.vue -->
<template>
    <div
      class="youtube-player"
      :style="playerStyle"
      @mousedown="startDrag"
      ref="playerWindow"
    >
      <v-card>
        <!-- Cabeçalho com botão de fechar -->
        <v-card-title class="draggable-header">
            <v-icon style="color: #1d4ed8;">mdi-youtube</v-icon>
          <span style="color: #1d4ed8;">Work Tutorial</span>
          <v-btn icon @click="fecharPlayer" class="close-button">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <!-- Corpo do Card com o player -->
        <v-card-text>
          <div id="player-container">
            <div
              id="player"
              style="width: 100%; height: 400px; max-height: 70vh;"
            ></div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  /* global YT */
  
  export default {
    name: "YoutubePlayer",
    props: {
      videoId: {
        type: String,
        required: false,
        default: "nvtjKJqbWus", // Substitua pelo ID do vídeo desejado
      },
    },
    data() {
      return {
        player: null,
        isLoading: false,
        isDragging: false,
        offset: { x: 0, y: 0 },
        top: 100, // Posição inicial
        left: 100, // Posição inicial
      };
    },
    computed: {
      playerStyle() {
        return {
          position: "absolute",
          top: `${this.top}px`,
          left: `${this.left}px`,
          cursor: this.isDragging ? "grabbing" : "grab",
          zIndex: 1000,
        };
      },
    },
    methods: {
      fecharPlayer() {
        this.$emit("close");
      },
      openPlayer() {
        if (!this.player && !document.getElementById("youtube-api-script")) {
          this.isLoading = true;
          this.initializePlayer();
        } else if (!this.player && document.getElementById("youtube-api-script")) {
          this.createPlayer();
        }
      },
      initializePlayer() {
        if (document.getElementById("youtube-api-script")) {
          this.createPlayer();
          return;
        }
        const script = document.createElement("script");
        script.id = "youtube-api-script";
        script.src = "https://www.youtube.com/iframe_api";
        script.onload = () => {
          window.onYouTubeIframeAPIReady = this.createPlayer;
        };
        document.body.appendChild(script);
      },
      createPlayer() {
        this.player = new YT.Player("player", {
          videoId: this.videoId,
          playerVars: {
            autoplay: 1,
            controls: 1,
          },
          events: {
            onReady: (event) => {
              event.target.playVideo();
              this.isLoading = false;
            },
          },
        });
      },
      startDrag(event) {
        // Verifica se o clique é na área de arrastar (header)
        if (!event.target.closest(".draggable-header")) return;
  
        this.isDragging = true;
        this.offset.x = event.clientX - this.left;
        this.offset.y = event.clientY - this.top;
  
        document.addEventListener("mousemove", this.onDrag);
        document.addEventListener("mouseup", this.stopDrag);
      },
      onDrag(event) {
        if (!this.isDragging) return;
        this.left = event.clientX - this.offset.x;
        this.top = event.clientY - this.offset.y;
      },
      stopDrag() {
        this.isDragging = false;
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.stopDrag);
      },
    },
    mounted() {
      this.openPlayer();
    },
    beforeDestroy() {
      if (this.player) {
        this.player.destroy();
        this.player = null;
      }
    },
  };
  </script>
  
  <style scoped>
  .youtube-player {
    /* Adiciona uma sombra e borda para destacar a janela */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
  .draggable-header {
    cursor: grab;
    user-select: none;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  #player-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #player {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  </style>
  