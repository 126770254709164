<template>
  <v-dialog v-model="showDialog" :width="dialogWidth" transition="dialog-transition">
    <v-card>
      <v-card-title class="centered-title">
        <h4>{{ titulo }}</h4>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="message" v-html="formatMessage(mensagem)"></div>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-spacer></v-spacer>
        <v-btn
          v-if="showConfirmDialog"
          color="primary"
          @click="confirmar"
          style="background-color: #1d4ed8; color: white; margin-right: 0.3cm"
        >
          Sim
        </v-btn>
        <v-btn
          v-if="showConfirmDialog"
          color="error"
          @click="cancelar"
          style="background-color: #1d4ed8; color: white"
        >
          Não
        </v-btn>
        <v-btn
          v-else
          color="primary"
          @click="fecharDialog"
          style="background-color: #1d4ed8; color: white"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      mensagem: "",
      titulo: "",
      callback: null,
      showConfirmDialog: false,
      dialogWidth: "auto",
    };
  },
  methods: {
    showMsg(titulo, mensagem) {
      this.titulo = titulo;
      this.mensagem = mensagem;
      this.dialogWidth = this.calculateDialogWidth(mensagem);
      this.showConfirmDialog = false;
      this.showDialog = true;
    },
    showConfirm(titulo, mensagem, callback) {
      this.titulo = titulo;
      this.mensagem = mensagem;
      this.dialogWidth = this.calculateDialogWidth(mensagem);
      this.callback = callback;
      this.showConfirmDialog = true;
      this.showDialog = true;
    },
    confirmar() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      if (typeof this.callback === "function") {
        this.callback(true);
      }
    },
    cancelar() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      if (typeof this.callback === "function") {
        this.callback(false);
      }
    },
    fecharDialog() {
      this.showDialog = false;
      this.titulo = "";
      this.mensagem = "";
      this.showConfirmDialog = false;
    },
    formatMessage(message) {
      return message.replace(/\n/g, "<br>");
    },
    calculateDialogWidth(message) {
      const length = message.length;
      if (length < 100) return "300px";
      if (length < 500) return "400px";
      return "90%";
    },
  },
};
</script>

<style scoped>
.v-dialog {
  width: auto !important;
  min-width: 300px;
  max-width: 50%;
  max-height: 50%; 
}

.v-card {
  max-width: 100%;
  overflow: auto; 
  max-height: 70%;
}

.message {
  font-size: 16px;
  margin-bottom: 16px;
  white-space: pre-wrap;
  word-break: break-word;
}

.centered-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
