<template>
  <div id="app">
    <Loading :loading="loading"></Loading>
    <SnackBar :show="snack.show" :text="snack.msg" :type="snack.type" @dismissed="onDismissed"/>
    <Header v-if="!regraExibicao()" @drawer="drawer = !drawer"/>
    <Menu v-if="!regraExibicao()" v-model="drawer"/> 
    <Content/>
    <Footer  v-if="!regraExibicao()"/>
    
  </div>
</template>

<script>
import Header from './components/template/Header'
import Menu from './components/template/Menu'
import Content from './components/template/Content'
import Footer from './components/template/Footer'
import LoginService from './service/login'
import { mapGetters, mapActions} from 'vuex'
import SnackBar from './components/SnackBar'
import Loading from './components/Loading'
export default {
  name: 'App', 
   components: {
     Header,
     Menu,
     Content,
     Footer,
     SnackBar,Loading
         },
    computed: {
      ...mapGetters(['snack', 'loading'])
    },  
    data () {
      return {
        drawer: false,
        isAuthenticated:false
        //
      }
    },
    beforeMount(){
      this.goToDashboard()
    },
    methods: {
      ...mapActions(['clearSnack','usernameAction']),

      onDismissed () {
        this.clearSnack()
      },
       goToDashboard() {          
          LoginService.getUser().then((response)=>{
            this.usernameAction(response.data)
            localStorage.setItem('id',response.data.id)
            if(this.$route.name != 'Monitor'){
              if(localStorage.getItem('permissoes') !== 'ROLE_MOTORISTA' && localStorage.getItem('permissoes') !== 'ROLE_USUARIO_MDFE_ENCERRAMENTO'){
                this.$router.push('/')
              }else{
                this.$router.push('/mdfee')
              }
            }   
          }).catch(()=>{
            localStorage.clear()
            if(this.$route.name != 'Login'){
              this.$router.push('/login')
            }
          })
        },
        regraExibicao(){
          return this.$route.name == 'Home' || this.$route.name == 'Login'
        }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:##FFFFFF;
  /* margin-top: 60px; */
  width: 100%;
  height: 100vh;

}
</style>