<template>
  <v-container data-app grid-list-xl fluid style="margin-top: 1vh">
    <ShowMessage ref="message"></ShowMessage>
    <v-layout row wrap>
      <v-flex>
        <BaseWidget title="Relatório MDF-e">
          <v-layout row wrap>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Pesquisar por:</b></v-label>
                <v-select
                  v-model="pesquisaSelecionado"
                  :items="listaPesquisa"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6">
                <v-label><b style="color: transparent">P</b></v-label>
                <v-text-field
                  :label="'Digite os dados do ' + pesquisaSelecionado"
                  :disabled="!pesquisaSelecionado"
                  v-if="pesquisaSelecionado != 'Transportador'"
                  solo
                  v-model="tfPesquisa"
                ></v-text-field>
                <v-autocomplete
                  auto-select-first
                  :label="'Digite os dados do ' + pesquisaSelecionado"
                  v-model="tfPesquisa"
                  v-if="pesquisaSelecionado == 'Transportador'"
                  :items="listaParticipantes"
                  item-text="nome"
                  item-value="nome"
                  solo
                  clearable
                ></v-autocomplete>
              </v-col>
              <br />
              <v-col class="d-flex" cols="12" sm="4" style="text-align: left"></v-col>

              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Situação:</b></v-label>
                <v-select
                  :items="listaSituacao"
                  item-value="id"
                  item-text="nome"
                  v-model="situacaoSelecionada"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="5" style="text-align: left">
                <v-label style="margin-right: 10px"><b>Período:</b></v-label>
                <div style="display: flex; align-items: center">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <p style="margin: 2%">à</p>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted2"
                        label="Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date2 = parseDate(dateFormatted2)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                sm="2"
                style="text-align: right; align-items: center; display: flex"
              >
                <div class="text-center mt-3">
                  <v-btn
                    dark
                    rounded=""
                    style="background-color: #1d4ed8"
                    @click="filtrar()"
                  >
                    <v-icon left> mdi-filter </v-icon>
                    <b>Filtrar</b>
                  </v-btn>
                </div>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" style="position: relative">
                <v-card class="mx-auto responsivo" max-width="300" tile>
                  <v-list dense>
                    <v-label><b style="font-size: 1.8vh">Legenda</b></v-label>
                    <v-list-item-group color="primary">
                      <v-list-item v-for="(item, i) in legenda" disabled :key="i">
                        <v-list-item-icon>
                          <v-icon v-text="item.icon" :style="item.color"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                            style="
                              color: #1d4ed8;
                              text-align: left;
                              margin-left: 5%;
                              font-size: 1.5vh;
                            "
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-layout>
        </BaseWidget>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar style="background-color: #1d4ed8" class="elevation-1">
          <v-toolbar-title>
            <h5 style="color: white">Lista de MDF-es</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex xs5 md3>
            <h5 style="color: white">Total: {{ this.valorTotal }}</h5>
          </v-flex>
        </v-toolbar>
        <v-skeleton-loader v-if="showLoader" type="table"></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headers"
              :items="listaCtes"
              :single-expand="singleExpand"
              show-expand
              item-key="idDocumento"
              class="elevation-1"
            >
              <template v-slot:item.situacao="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColor(item.situacao)"
                  dark
                  >{{ item.situacao }}
                </v-chip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div style="padding: 1%">
                    <td :colspan="headers.length">
                      <h3 style="margin-top: 1%; text-align: left">Detalhes da MDF-e</h3>
                      <div style="padding: 0%; text-align: left">
                        <p>Série: {{ item.serie }}</p>
                        <p>Origem: {{ item.origem }}</p>
                        <p>Destino: {{ item.destino }}</p>
                        <p>Razão social: {{ item.nomeTransportador }}</p>
                        <p>
                          CNPJ do Transportador:
                          {{
                            item.cnpj.replace(
                              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                              "$1.$2.$3/$4-$5"
                            )
                          }}
                        </p>
                        <p>Placa: {{ item.placa }}</p>
                        <p>Chave MDF-e: {{ item.chave }}</p>
                        <v-divider
                          style="margin-bottom: 10px; margin-top: 5px; width: 80vw"
                        ></v-divider>
                        <v-btn
                          dark
                          rounded=""
                          style="background-color: #1d4ed8; margin-right: 10px"
                          @click="download(item.dactePath, item.chave, 'pdf')"
                        >
                          <b>Download DAMDF-e</b>
                        </v-btn>
                        <v-btn
                          dark
                          rounded=""
                          style="background-color: #1d4ed8"
                          @click="download(item.xmlPath, item.chave, 'xml')"
                        >
                          <b>Download XML</b>
                        </v-btn>
                        <v-btn
                          dark
                          rounded=""
                          v-if="item.situacao == 'Cancelado'"
                          style="background-color: #1d4ed8; margin-left: 10px"
                          @click="download(item.xmlCancelamentoPath, item.chave, 'xml')"
                        >
                          <b>Download XML Cancelamento</b>
                        </v-btn>
                        <v-btn
                          dark
                          rounded=""
                          v-if="item.situacao == 'Autorizado'"
                          style="background-color: #1d4ed8; margin-left: 10px"
                          @click="confirmation(item.chave)"
                        >
                          <b>Encerrar MDF-e</b>
                        </v-btn>
                        <v-btn
                          dark
                          rounded=""
                          v-if="item.situacao == 'Autorizado'"
                          style="background-color: #1d4ed8; margin-left: 10px"
                          @click="cancelar(item.chave)"
                        >
                          <b>Cancelar MDF-e</b>
                        </v-btn>
                        <v-btn
                          dark
                          rounded=""
                          v-if="item.situacao == 'Autorizado'"
                          style="background-color: #1d4ed8; margin-left: 10px"
                          @click="incluirCondutor(item.chave)"
                        >
                          <b>Incluir Condutor</b>
                        </v-btn>
                      </div>
                    </td>
                  </div>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogCondutor" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Inclusão do Condutor</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                label="Chave"
                v-model="condutor.chave"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field
                label="Nome"
                v-model="condutor.nome"
                maxlength="60"
                @input="condutor.nome = $event.toUpperCase()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                label="CPF"
                v-model="condutor.cpf"
                maxlength="11"
                v-numbers-only
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            style="background-color: #1d4ed8; color: white"
            @click="clickBtnCancelarCondutor"
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnIncluirCondutor"
            style="background-color: #1d4ed8; color: white"
            >Prosseguir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseWidget from "../components/BaseWidget";
import Movimentacao from "../service/movimentacao";
import ShowMessage from "../components/ShowMessage";
import { mapActions, mapMutations } from "vuex";
import Mdfe from "../service/mdfe";
import print from "print-js";
import swal from "sweetalert";
export default {
  name: "Dashboard",
  components: {
    BaseWidget,
    ShowMessage,
  },
  directives: {
    "numbers-only": {
      bind(el) {
        el.addEventListener("input", function (event) {
          const inputValue = event.target.value;
          event.target.value = inputValue.replace(/\D/g, "");
        });
      },
    },
  },
  data() {
    return {
      mensagem: "",
      headers: [
        { align: "start", sortable: false },
        { text: "Num. Doc", align: "left", value: "numdoc" },
        { text: "Transportador", align: "left", value: "nomeTransportador" },
        { text: "Data", value: "dt", align: "left" },
        { text: "Origem", value: "origem", align: "left" },
        { text: "Destino", value: "destino", align: "left" },
        { text: "Valor", value: "valorTotal", align: "left" },
        { text: "Status", value: "situacao", align: "center" },
        { text: "Detalhes", value: "data-table-expand" },
      ],
      listaCtes: [],
      valorTotal: "R$ 0,00",
      valorICMS: "R$ 0,00",
      listaPesquisa: ["Transportador", "Núm. documento", "Placa"],
      listaSituacao: [
        { id: 11, nome: "Todos" },
        { id: 0, nome: "Gerado" },
        { id: 1, nome: "Enviado" },
        { id: 2, nome: "Autorizado" },
        { id: 3, nome: "Rejeitado" },
        { id: 4, nome: "Encerrado" },
        { id: 5, nome: "Cancelado" },
      ],
      listaParticipantes: [],
      legenda: [
        {
          text: "GERADO / ENVIADO",
          icon: "mdi-checkbox-blank-circle",
          color: "color:#1565C0; font-size:15px",
        },
        {
          text: "AUTORIZADO",
          icon: "mdi-checkbox-blank-circle",
          color: "color:#068206; font-size:15px",
        },
        {
          text: "REJEITADO / CANCELADO",
          icon: "mdi-checkbox-blank-circle",
          color: "color:#F44336; font-size:15px",
        },
        {
          text: "ENCERRADO",
          icon: "mdi-checkbox-blank-circle",
          color: "color:#00897B; font-size:15px",
        },
      ],
      pesquisaSelecionado: "",
      tfPesquisa: "",
      selecaoFiltro: {
        campo: "",
        valor: "",
        dtInicio: null,
        dtFim: null,
        situacao: null,
      },
      showLoader: true,
      situacaoSelecionada: 11,
      situacaoSelecionadaRelatorio: 11,
      tipoFiltroRelatorio: "",
      filtroRelatorio: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted2: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      menu2: false,
      singleExpand: true,
      dialogCondutor: false,
      condutor: {
        nome: "",
        cpf: "",
      },
    };
  },
  mounted() {
    Movimentacao.getAllParticipantes()
      .then((response) => {
        this.listaParticipantes = response.data;
      })
      .catch(() => {
        window.location.reload();
      });
    Movimentacao.getAllMdfeDocuments()
      .then((response) => {
        // this.valorICMS = response.data.valorTotalIcms.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        this.valorTotal = response.data.valorTotalFrete.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        response.data.movimentacoes.forEach((item) => {
          this.listaCtes.push({
            idDocumento: item.idDocumento,
            numdoc: item.numeroDocumento,
            serie: item.serie,
            cnpj: item.transportador.cnpj,
            nomeTransportador: item.transportador.nome,
            dt: this.formatDate(new Date(item.dataEmissao).toISOString().substr(0, 10)),
            origem: item.ufEmitente,
            destino: item.ufDestinatario,
            valorTotal: item.valorTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            placa: item.placa,
            situacao: this.getStatus(item.status),
            chave: item.chaveAcesso,
            dactePath: item.dactePath,
            xmlPath: item.xmlPath,
            xmlCancelamentoPath: item.xmlCancelamentoPath,
          });
          this.showLoader = false;
        });
        this.showLoader = false;
      })
      .catch((e) => {
        console.log(e);
        this.showLoader = false;
        window.location.reload();
      });

    var doSave = (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key == "p" || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)
      ) {
        this.printTable();
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("keydown", doSave);
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),
    filtrar() {
      this.showLoader = true;
      this.situacaoSelecionadaRelatorio = this.situacaoSelecionada;
      if (this.tfPesquisa != "") {
        this.selecaoFiltro.campo = this.pesquisaSelecionado;
        this.selecaoFiltro.valor = this.tfPesquisa;
        this.tipoFiltroRelatorio = this.pesquisaSelecionado;
        this.filtroRelatorio = this.tfPesquisa;
      } else {
        this.selecaoFiltro.campo = "";
        this.selecaoFiltro.valor = "";
        this.tipoFiltroRelatorio = "";
        this.filtroRelatorio = "";
      }
      this.selecaoFiltro.situacao = this.situacaoSelecionada;
      this.selecaoFiltro.dtInicio = this.date;
      this.selecaoFiltro.dtFim = this.date2;
      Movimentacao.getMdfeFilteredDocuments(this.selecaoFiltro)
        .then((response) => {
          this.listaCtes = [];
          this.valorTotal = response.data.valorTotalFrete.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          response.data.movimentacoes.forEach((item) => {
            this.listaCtes.push({
              idDocumento: item.idDocumento,
              numdoc: item.numeroDocumento,
              serie: item.serie,
              cnpj: item.transportador.cnpj,
              nomeTransportador: item.transportador.nome,
              dt: this.formatDate(new Date(item.dataEmissao).toISOString().substr(0, 10)),
              origem: item.ufEmitente,
              destino: item.ufDestinatario,
              valorTotal: item.valorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              placa: item.placa,
              situacao: this.getStatus(item.status),
              chave: item.chaveAcesso,
              dactePath: item.dactePath,
              xmlPath: item.xmlPath,
              xmlCancelamentoPath: item.xmlCancelamentoPath,
            });
          });
          this.showLoader = false;
        })
        .catch(() => {
          this.listaCtes = [];
          this.showLoader = false;
          window.location.reload();
        });
    },
    download(path, chave, extension) {
      let type = "";
      extension == "pdf" ? (type = "application/pdf") : "text/xml";

      Movimentacao.download(path, "MDFE").then((response) => {
        var blob = new Blob([this.base64ToArrayBuffer(response.data)], { type: type });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = chave + "." + extension;
        link.download = fileName;
        link.click();
      });
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    confirmation(chave) {
      swal({
        title: "Confirmação",
        text: "Deseja encerrar o MDF-e?",
        icon: "warning",
        buttons: {
          cancel: "Não",
          sim: true,
        },
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.allMDFE();
          Mdfe.encerrar(chave)
            .then(() => {
              swal("Encerramento Concluído com sucesso!", {
                icon: "success",
              }).then(() => {
                this.allMDFE();
              });
            })
            .catch((err) => {
              swal(
                "Não foi possível Encerrar o MDF-e, motivo: " + err.response.data.motivo,
                {
                  icon: "error",
                }
              ).then(() => {
                this.allMDFE();
              });
            });
        }
      });
    },
    incluirCondutor(chave) {
      this.condutor = {
        nome: "",
        cpf: "",
        chave: chave,
      };

      this.dialogCondutor = true;
    },

    clickBtnIncluirCondutor() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja inserir este condutor?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");

            if (this.validarDadosCondutor()) {
              Mdfe.incluirCondutor(this.condutor.chave, this.condutor.nome, this.condutor.cpf)
                .then(() => {
                  swal("Condutor incluído com sucesso!", {
                    icon: "success",
                  }).then(() => {
                    this.dialogCondutor = false;
                    this.allMDFE();
                  });
                })
                .catch((err) => {
                  swal(
                    "Não foi possível incluir o Condutor, motivo: " +
                      err.response.data.motivo,
                    {
                      icon: "error",
                    }
                  ).then(() => {
                    this.allMDFE();
                  });
                });
            }

            this.setLoading(false);
          }
        }
      );
    },
    clickBtnCancelarCondutor() {
      this.dialogCondutor = false;
    },
    validarDadosCondutor() {
      this.condutor.nome = this.condutor.nome.trim();

      if (!this.condutor.nome) {
        swal("Campo Nome é obrigatório", {
          icon: "error",
        });
        return false;
      }

      if (this.condutor.nome.length < 2) {
        swal("Campo Nome deve possuir mais de um caracteres", {
          icon: "error",
        });
        return false;
      }

      if (!this.condutor.cpf) {
        swal("Campo CPF é obrigatório", {
          icon: "error",
        });
        return false;
      }

      if (this.condutor.cpf.length != 11) {
        swal("Campo CPF deve possuir 11 caracteres", {
          icon: "error",
        });
        return false;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.condutor.nome)) {
        swal("Campo Nome deve conter apenas caracteres alfanuméricos", {
          icon: "error",
        });

        return false;
      }

      return true;
    },
    cancelar(chave) {
      swal({
        text: "Para cancelar a MDF-e, insira uma observação de cancelamento",
        content: "input",
        button: {
          text: "Prosseguir",
        },
      }).then((name) => {
        if (this.vazio(name) || name.length < 15) {
          swal("A observação não pode ser vazia e com pelo menos 15 caracteres.").then(
            () => {
              throw null;
            }
          );
        } else {
          swal({
            title: "Confirmação",
            text: "Deseja cancelar o MDF-e?",
            icon: "warning",
            buttons: {
              cancel: "Não",
              sim: true,
            },
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.allMDFE();
              Mdfe.cancelar(chave, name)
                .then(() => {
                  swal("Cancelamento Concluído com sucesso!", {
                    icon: "success",
                  }).then(() => {
                    this.allMDFE();
                  });
                })
                .catch((err) => {
                  swal(
                    "Não foi possível Cancelar o MDF-e, motivo: " +
                      err.response.data.motivo,
                    {
                      icon: "error",
                    }
                  ).then(() => {
                    this.allMDFE();
                  });
                });
            }
          });
        }
      });
    },
    printTable() {
      let titulo = "WorkCTE";
      let datas = "";
      let valores =
        " <b>Total Frete:</b> " +
        this.valorTotal +
        " <b>Total de CTEs: </b>" +
        this.listaCtes.length;
      let filtro = "Filtrar por - ";
      if (this.selecaoFiltro.dtInicio != null) {
        datas +=
          this.formatDate(
            new Date(this.selecaoFiltro.dtInicio).toISOString().substr(0, 10)
          ) +
          " à " +
          this.formatDate(new Date(this.selecaoFiltro.dtFim).toISOString().substr(0, 10));
      } else {
        datas += "todas as datas";
      }
      this.listaSituacao.forEach((situacao) => {
        situacao.id == this.situacaoSelecionadaRelatorio
          ? (filtro += "Situação: " + situacao.nome)
          : "";
      });

      this.listaPesquisa.forEach((item) => {
        if (this.filtroRelatorio != "" && item === this.tipoFiltroRelatorio) {
          filtro += " | " + this.tipoFiltroRelatorio + ": " + this.filtroRelatorio;
        }
      });

      print({
        printable: this.listaCtes,
        properties: [
          { field: "numdoc", displayName: "Nº Doc" },
          { field: "cnpj", displayName: "Transp" },
          { field: "dt", displayName: "Data" },
          { field: "origem", displayName: "Ori" },
          { field: "destino", displayName: "Dest" },
          { field: "valorTotal", displayName: "Valor Total" },
          { field: "placa", displayName: "Placa" },
          { field: "situacao", displayName: "Situação" },
          { field: "chave", displayName: "Chave" },
        ],
        type: "json",
        header:
          '<h1 class="custom-h1">' +
          titulo +
          '</h1><h2 class="custom-h2">Relatório - ' +
          datas +
          '</h2><h3 class="custom-h3">' +
          filtro +
          "</h3><br/>" +
          valores,
        style:
          ".custom-h1 { color: #1d4ed8; text-align:center; margin-bottom:1px }.custom-h2 { color: #1d4ed8; text-align:center; }.custom-h3 { color: #1d4ed8; text-align:center; }",
        gridHeaderStyle: "color: black;  border: 2px solid black;font-size:11px",
        gridStyle: " border: 2px solid black;font-size:11px;text-align:center",
      });
    },
    allMDFE() {
      this.listaCtes = [];
      this.showLoader = true;
      Movimentacao.getAllMdfeDocuments()
        .then((response) => {
          // this.valorICMS = response.data.valorTotalIcms.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
          this.valorTotal = response.data.valorTotalFrete.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          response.data.movimentacoes.forEach((item) => {
            this.listaCtes.push({
              idDocumento: item.idDocumento,
              numdoc: item.numeroDocumento,
              serie: item.serie,
              cnpj: item.transportador.cnpj,
              nomeTransportador: item.transportador.nome,
              dt: this.formatDate(new Date(item.dataEmissao).toISOString().substr(0, 10)),
              origem: item.ufEmitente,
              destino: item.ufDestinatario,
              valorTotal: item.valorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              placa: item.placa,
              situacao: this.getStatus(item.status),
              chave: item.chaveAcesso,
              dactePath: item.dactePath,
              xmlPath: item.xmlPath,
              xmlCancelamentoPath: item.xmlCancelamentoPath,
            });
            this.showLoader = false;
          });
          this.showLoader = false;
        })
        .catch((e) => {
          console.log(e);
          this.showLoader = false;
          window.location.reload();
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    vazio(string) {
      var isEmpty = (data) => {
        if (typeof data === "object") {
          if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]") {
            return true;
          } else if (!data) {
            return true;
          }
          return false;
        } else if (typeof data === "string") {
          if (!data.trim()) {
            return true;
          }
          return false;
        } else if (typeof data === "undefined") {
          return true;
        } else {
          return false;
        }
      };
      return isEmpty(string);
    },
    getColor(status) {
      if (status == "Enviado" || status == "Gerado") {
        return "#1565C0";
      } else if (status == "Autorizado") {
        return "#068206";
      } else if (status == "Rejeitado") {
        return "#F44336";
      } else if (status == "Encerrado") {
        return "#00897B";
      } else if (status == "Cancelado") {
        return "#F44336";
      }
    },
    getStatus(status) {
      if (status == 1) {
        return "Enviado";
      } else if (status == 2) {
        return "Autorizado";
      } else if (status == 3) {
        return "Rejeitado";
      } else if (status == 4) {
        return "Encerrado";
      } else if (status == 5) {
        return "Cancelado";
      } else if (status == 0) {
        return "Gerado";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-treeselect__control {
  border-radius: 0;
}

.responsivo {
  position: absolute;
  top: -100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    position: relative;
    top: 0%;
  }
}
</style>
