<template>
    <v-navigation-drawer
            class="menu-lateral"
            style="background-color:#1d4ed8"
            dark
            v-model="localDrawer"
            absolute
            overflow
            app
            temporary
                     
    >
        <v-app-bar flat class="transparent" style="background-color:#1d4ed8" >
           <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-avatar color="black" style="background-color:#3D4348" size="48">
                    <span class="black--text headline">{{ this.nomeUsuario() }}</span>
                    </v-avatar>
                </v-list-item-avatar>

                <v-list-item-title>{{this.username.description}}</v-list-item-title>

            </v-list-item>
        </v-app-bar>
        <v-list dense>
            <v-divider></v-divider>
            <template v-for="(item, index) in itens">
                <MenuItem :icon="item.icon" :title="item.title" :route-name="item.routeName" :key="index"/>
            </template>
        </v-list>
        <v-divider></v-divider>
        <v-list-item 
                 
                 active-class="white--text"
                 class="item-menu"
                 style="cursor:pointer" @click="clear"
        >
            <v-list-item-action>
                <v-icon class="item-menu">mdi-power</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title class="item-menu white" style="color:white">Sair</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
    import MenuItem from './MenuItem'
    import { menu } from '../../config'
    import { mapGetters } from 'vuex'
    import store from '../../store'; 

    export default {
        name: 'Menu',
        components: { MenuItem },
        props: { value: { type: Boolean } },
        computed: {
            ...mapGetters(['username']),
            itens () {
                var menuAutorizado = [];
                var permissao = store.state.user.permissao;
                menu.forEach(item=>{
                    if(item.permissoes.find(r => r === permissao)) menuAutorizado.push(item)
                })
                return menuAutorizado
            }
        },
        data () {
            return {
                localDrawer: this.value
            }
        },
        watch: {
            value () {
                this.localDrawer = this.value
            },
            localDrawer () {
                this.$emit('input', this.localDrawer)
            }
        },
        methods:{
            clear(){
                localStorage.clear();
                this.$router.go('/')
            },
            nomeUsuario() {
                    let nome = this.username.description.substring(0,1)
                return (
                    nome
                );
            },
        }
    }
</script>

<style scoped>
    .menu-lateral {
        background-color: blue;
        /*color: #293846;*/
    }

    

</style>
