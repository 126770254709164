<template>
    <v-footer app  inset style="background-color:#1d4ed8">
        <!-- <span >USUÁRIO - {{this.nomeUsuario()}} - Versão do Sistema - 2.0 </span> -->
         <v-col class="text-left">
            USUÁRIO - {{this.nomeUsuario()}}
         </v-col>
         <v-col class="text-right"> Versão do Sistema - 2.0</v-col>
        <!-- <span >USUÁRIO-0001-ADMINISTRADOR </span>
        <span >USUÁRIO-0001-ADMINISTRADOR </span>
        <span >USUÁRIO-0001-ADMINISTRADOR </span> -->
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'Footer',
        computed:{
            ...mapGetters(['username']),
        },
        methods:{
            nomeUsuario() {
                let usuario = this.username
                return (
                    usuario.description
                );
            },
        }
        
    }
</script>

<style scoped>
    .text-left{
        font-size: 11px;    
        text-align: left;
        color: white;
        padding: 0%;
    }
    .text-right{
        font-size: 11px;    
        text-align: right;
        color: white;
        padding: 0%;
    }
</style>
