import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueTheMask from 'vue-the-mask'
import money from 'vuejs-money'

Vue.use(VueRouter)
Vue.use(VueTheMask)
Vue.use(money)

const router = new VueRouter({
    // mode:'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/cte',
            name: 'CTE',
            component: () => import('../views/Dashboard.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR']
            }
        },
        {
            path: '/mdfe',
            name: 'MDFE',
            component: () => import('../views/DashboardMDFE.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR']
            }
        },
        {
            path: '/export',
            name: 'Exportar',
            component: () => import('../views/Exportar.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR']
            }
        },
        {
            path: '/xlsx',
            name: 'Xlsx',
            component: () => import('../views/ExportarCsv.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_CONTADOR']
            }
        },
        {
            path: '/receber',
            name: 'Receber',
            component: () => import('../views/DashboardReceber.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR']
            }
        },
        {
            path: '/',
            name: 'Monitor',
            component: () => import('../views/Home.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR']
            }
        },
        {
            path: '/mdfee',
            name: 'MDFEE',
            component: () => import('../views/DashboardMotorista.vue'),
            meta: {
                roles: ['ROLE_MOTORISTA','ROLE_USUARIO_MDFE_ENCERRAMENTO', 'ROLE_CONTADOR']
            }
        },
        {
            path:'/login',
            name:'Login',
            component:() => import('../views/Login.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MOTORISTA','ROLE_USUARIO_MDFE_ENCERRAMENTO','ROLE_MODERADOR','ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR']
            }
        },
        {
            path:'/portal',
            name:'Home',
            component:() => import('../views/Home.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MOTORISTA','ROLE_USUARIO_MDFE_ENCERRAMENTO','ROLE_MODERADOR','ROLE_USUARIO_MONITOR','ROLE_USUARIO_OPERADOR','ROLE_CONTADOR']
            }
        },
        {
            path: '/tranportador',
            name: 'Tranportador',
            component: () => import('../views/CadastroTransportador.vue'),
            meta: {
                roles: ['ROLE_ADMIN','ROLE_MODERADOR']
            }
        },
        {
            path: '/atualiza-certificado',
            name: 'AtualizaCertificado',
            component: () => import('../views/AtualizaCertificado.vue'),
            meta: {
                roles: ['ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR']
            }
        },    
        {
            path: '/emissao-viagem',
            name: 'EmissaoViagem',
            component: () => import('../views/EmissaoViagem.vue'),
            meta: {
                roles: ['ROLE_USUARIO','ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO_MONITOR']
            }
        },
        {
            path: '/tributacao',
            name: 'Tributacao',
            component: () => import('../views/Tributacao.vue'),
            meta: {
                roles: ['ROLE_CONTADOR']
            }
        },
        { 
            path: '/certificados-digitais',
            name: 'CertificadosDigitais', 
            component: () => import('../views/CertificadosDigitais.vue'),
            meta: {
                roles:['ROLE_ADMIN','ROLE_MODERADOR','ROLE_USUARIO','ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR'] 
            }
        }

    ]
})

router.beforeEach((to, from, next) => {
    var isAuthenticated = store.state.user.isLoggedIn
    var permissao = store.state.user.permissao
    if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    else if (!isAuthenticated)next();
    else {
            to.matched.forEach( route => {
                route.meta.roles.forEach(mr => {
                    if(permissao == mr) next()
                })
            })
    }
  })
export default router;