<template>
  <v-app>
    <v-container data-app fluid>
      <v-alert
        v-if="errorMessage"
        type="error"
        dismissible
        @dismiss="errorMessage = ''"
        class="mb-4"
      >
        {{ errorMessage }}
      </v-alert>

      <v-card class="pa-5 mb-5" elevation="4">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6" lg="4">
            <v-menu
              v-model="menuVencimento"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedVencimento"
                  label="Data de Vencimento"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.vencimento"
                @input="menuVencimento = false"
                locale="pt-BR"
                class="custom-calendar"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="6" lg="2">
            <v-btn
              color="primary"
              @click="fetchData"
              :loading="loading"
              :disabled="loading"
              class="search-button"
              elevation="2"
              block
            >
              <v-icon left>mdi-magnify</v-icon>
              Pesquisar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="pa-3" elevation="4">
        <v-data-table
          :headers="headers"
          :items="filteredCertificados"
          :loading="loading"
          class="elevation-1"
          :items-per-page="10"
          :no-data-text="'Nenhum certificado encontrado.'"
          dense
          :footer-props="{
            'itemsPerPageText': 'Itens por página:',
            'itemsPerPageOptions': [10, 20, 30, 40],
            'showFirstLastPage': true,
            'pageText': '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <v-row class="px-4 py-2" align="center" justify="space-between">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="filters.cnpj"
                  label="Pesquisar CNPJ"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="filters.razaoSocial"
                  label="Pesquisar Razão Social"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="filters.status"
                  :items="['Todos', 'Ativo', 'Inativo']"
                  label="Filtrar por Status"
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.cnpj="{ item }">
            <span>{{ formatCNPJ(item.cnpj) }}</span>
          </template>

          <template v-slot:item.razao="{ item }">
            <span>{{ item.razao }}</span>
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="{
              'text-ativo': item.status === 'ATIVO',
              'text-inativo': item.status === 'INATIVO'
            }">
              {{ item.status }}
            </span>
          </template>

          <template v-slot:item.dataExpiracao="{ item }">
            <span>{{ formatDate(item.dataExpiracao) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CertificadosDigitais',
  data() {
    return {
      filters: {
        vencimento: null,
        status: 'Todos',
        cnpj: '',
        razaoSocial: '',
      },
      menuVencimento: false,
      headers: [
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Razão Social', value: 'razao' },
        { text: 'Status', value: 'status' },
        { text: 'Data Expiração', value: 'dataExpiracao' },
      ],
      certificados: [],
      loading: false,
      errorMessage: '',
    }
  },
  computed: {
    formattedVencimento() {
      if (!this.filters.vencimento) return ''
      const [year, month, day] = this.filters.vencimento.split('-')
      return `${day}/${month}/${year}`
    },
    filteredCertificados() {
      return this.certificados.filter(cert => {
        const matchesCnpj = cert.cnpj.includes(this.filters.cnpj)
        const matchesRazaoSocial = cert.razao.toLowerCase().includes(this.filters.razaoSocial.toLowerCase())
        const matchesStatus =
          this.filters.status === 'Todos' || cert.status === this.filters.status.toUpperCase()

        return matchesCnpj && matchesRazaoSocial && matchesStatus
      })
    },
  },
  methods: {
    formatCNPJ(cnpj) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
    },
    formatDate(dateStr) {
      const date = new Date(dateStr)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    },
    async fetchData() {
      this.errorMessage = ''

      if (!this.filters.vencimento) {
        this.errorMessage = 'Por favor, selecione a data de vencimento.'
        return
      }

      const vencimento = this.filters.vencimento
      const token = localStorage.getItem('token') || ''
      const idUsuario = localStorage.getItem("id")

      if (!idUsuario) {
        this.errorMessage = 'Erro ao obter o ID do usuário.'
        return
      }

      this.loading = true
      try {
        const response = await axios.get('https://api.cte.workinnovation.com.br/v1/certificados', {
          params: { vencimento, idUsuario },
          headers: { 'Authorization': `Bearer ${token}` },
        })
        this.certificados = response.data
      } catch (error) {
        this.errorMessage = 'Erro ao buscar os certificados.'
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.v-text-field {
  background-color: white;
}

.v-data-table {
  background-color: white;
}

.search-button {
  min-width: 150px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #1976D2;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #1565C0;
}

.text-ativo {
  color: #388e3c;
  font-weight: bold;
}

.text-inativo {
  color: #d32f2f;
  font-weight: bold;
}

.custom-calendar {
  background-color: #f5f5f5;
  color: #212121;
}

.v-select {
  background-color: white;
}

.v-text-field input {
  color: #424242;
}

@media (max-width: 600px) {
  .v-data-table {
    overflow-x: auto;
  }
}
</style>
