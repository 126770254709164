<template>
  <v-container data-app grid-list-xl fluid style="margin-top: 1vh">
    <v-layout row wrap>
      <v-flex>
        <BaseWidget title="Exportação de Relatórios XLSX">
          <v-layout row wrap>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Pesquisar por:</b></v-label>
                <v-select
                  v-model="pesquisaSelecionado"
                  :items="listaPesquisa"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6">
                <v-label><b style="color: transparent">P</b></v-label>
                <v-text-field
                  :label="'Digite os dados do ' + pesquisaSelecionado"
                  :disabled="!pesquisaSelecionado"
                  v-if="pesquisaSelecionado != 'Transportador'"
                  solo
                  v-model="tfPesquisa"
                ></v-text-field>
                <v-autocomplete
                  auto-select-first
                  :label="'Digite os dados do ' + pesquisaSelecionado"
                  v-model="tfPesquisa"
                  v-if="pesquisaSelecionado == 'Transportador'"
                  :items="listaParticipantes"
                  item-text="nome"
                  item-value="nome"
                  solo
                  clearable
                ></v-autocomplete>
              </v-col>
              <br />
              <v-col class="d-flex" cols="12" sm="4" style="text-align: left"></v-col>

              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Tipo Documento:</b></v-label>
                <v-select
                  :items="listaTipoDocumento"
                  item-value="id"
                  item-text="nome"
                  v-model="tipoDocumentoSelecionado"
                  solo
                ></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Situação:</b></v-label>
                <v-select
                  :items="listaFormato"
                  item-value="id"
                  item-text="nome"
                  v-model="formatoSelecionado"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="5" style="text-align: left">
                <v-label style="margin-right: 10px"><b>Período:</b></v-label>
                <div style="display: flex; align-items: center">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <p style="margin: 2%">à</p>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted2"
                        label="Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date2 = parseDate(dateFormatted2)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                sm="2"
                style="text-align: right; align-items: center; display: flex"
              >
                <div class="text-center mt-3">
                  <v-btn
                    dark
                    rounded=""
                    style="background-color: #1d4ed8"
                    @click="filtrar()"
                  >
                    <v-icon left> mdi-filter </v-icon>
                    <b>Filtrar</b>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-layout>
        </BaseWidget>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar style="background-color: #1d4ed8" class="elevation-1">
          <v-toolbar-title>
            <h5 style="color: white">Lista de Notas</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex xs5 md2 v-if="listaCtes.length != 0">
            <v-btn
              dark
              rounded=""
              style="background-color: #1d4ed8"
              :disabled="this.baixando == true"
              @click="downloadTable()"
              ><b>Download</b></v-btn
            >
          </v-flex>
          <v-progress-circular
            v-if="baixando == true"
            indeterminate
            style="color: white"
          ></v-progress-circular>
        </v-toolbar>
        <v-skeleton-loader v-if="showLoader" type="table"></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headers"
              :items="listaCtes"
              item-key="idDocumento"
              class="elevation-1"
            >
              <template v-slot:item.situacao="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColor(item.situacao)"
                  dark
                  >{{ item.situacao }}
                </v-chip>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseWidget from "../components/BaseWidget";
import Movimentacao from "../service/movimentacao";
import { mapActions, mapMutations } from "vuex";
import * as JSZip from "jszip";
import xlsx from "json-as-xlsx";
import { saveAs } from "file-saver";
export default {
  name: "Dashboard",
  components: {
    BaseWidget,
  },
  data() {
    return {
      mensagem: "",
      baixando: false,
      headers: [
        { align: "start", sortable: false },
        { text: "Num. Doc", align: "left", value: "numdoc" },
        { text: "Transportador", align: "left", value: "nomeTransportador" },
        { text: "Data", value: "dt", align: "left" },
        { text: "Origem", value: "origem", align: "left" },
        { text: "Destino", value: "destino", align: "left" },
        { text: "Tipo", value: "tipo", align: "left" },
        { text: "Valor", value: "valorTotal", align: "left" },
        { text: "Status", value: "situacao", align: "center" },
      ],
      listaCtes: [],
      listaPesquisa: ["Transportador", "Núm. documento"],
      listaTipoDocumento: [
        { id: 0, nome: "Todos" },
        { id: 1, nome: "CT-e" },
        { id: 2, nome: "MDF-e" },
      ],
      listaFormato: [
        { id: 8, nome: "Todos" },
        { id: 2, nome: "Autorizada" },
        { id: 5, nome: "Cancelada" },
        { id: 4, nome: "Encerrada" },
      ],
      fieldsXls: [
        { value: "cnpj", label: "CNPJ" },
        { value: "nomeTransportador", label: "Nome Fantasia" },
        { value: "tipo", label: "Tipo" },
        { value: "situacao", label: "Situação" },
        { value: "chave", label: "Chave" },
        { value: "numdoc", label: "Nª do documento" },
        { value: "serie", label: "Série" },
        { value: "dt", label: "Data Emissão" },
        { value: "placa", label: "Placa" },
        { value: "origem", label: "Origem" },
        { value: "cidadeEmitente", label: "Cidade Origem" },
        { value: "destino", label: "Destino" },
        { value: "cidadeDestinatario", label: "Cidade Destinatário" },
        { value: "cfop", label: "CFOP" },
        { value: "cst", label: "CST" },
        { value: "valorTotalDecimal", label: "Valor Total" },
        { value: "valorIcmsDecimal", label: "Valor ICMS" },
        { value: "valorCargaDecimal", label: "Valor Total Carga" },
        { value: "idConhecimento", label: "Conhecimento" },
        
      ],

      listaParticipantes: [],
      listaBlobs: [],
      pesquisaSelecionado: "",
      tfPesquisa: "",
      selecaoFiltro: {
        campo: "",
        valor: "",
        dtInicio: null,
        dtFim: null,
        tipo: null,
        situacao: null,
      },
      showLoader: false,
      tipoDocumentoSelecionado: 0,
      formatoSelecionado: 8,
      situacaoSelecionadaRelatorio: 11,
      tipoFiltroRelatorio: "",
      filtroRelatorio: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted2: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      menu2: false,
      singleExpand: true,
    };
  },
  mounted() {
    Movimentacao.getAllParticipantes()
      .then((response) => {
        this.listaParticipantes = response.data;
      })
      .catch(() => {
        window.location.reload();
      });
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),
    filtrar() {
      this.showLoader = true;
      this.situacaoSelecionadaRelatorio = this.situacaoSelecionada;
      if (this.tfPesquisa != "") {
        this.selecaoFiltro.campo = this.pesquisaSelecionado;
        this.selecaoFiltro.valor = this.tfPesquisa;
        this.tipoFiltroRelatorio = this.pesquisaSelecionado;
        this.filtroRelatorio = this.tfPesquisa;
      } else {
        this.selecaoFiltro.campo = "";
        this.selecaoFiltro.valor = "";
        this.tipoFiltroRelatorio = "";
        this.filtroRelatorio = "";
      }
      this.selecaoFiltro.tipo = this.tipoDocumentoSelecionado;
      this.selecaoFiltro.situacao = this.formatoSelecionado;
      this.selecaoFiltro.dtInicio = this.date;
      this.selecaoFiltro.dtFim = this.date2;
      Movimentacao.getExportFilteredDocumentsCsv(this.selecaoFiltro)
        .then((response) => {
          console.log("PASSOU AQUI response: ", response);
          this.listaCtes = [];
          response.data.movimentacoes.forEach((item) => {
            this.listaCtes.push({
              idDocumento: item.idDocumento,
              idConhecimento: item.idConhecimento,
              numdoc: item.numeroDocumento,
              serie: item.serie,
              tipo: item.tipo,
              cnpj: item.transportador.cnpj,
              nomeTransportador: item.transportador.nome,
              dt: this.formatDate(new Date(item.dataEmissao).toISOString().substr(0, 10)),
              origem: item.ufEmitente,
              destino: item.ufDestinatario,
              valorTotal: item.valorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              valorIcms: item.valorIcms
                ? item.valorIcms.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : "-",
              valorTotalDecimal: item.valorTotal,
              valorIcmsDecimal: item.valorIcms ? item.valorIcms : "-",
              valorCargaDecimal:item.valorCarga ? item.valorCarga : "-",
              placa: item.placa,
              cidadeEmitente: item.cidadeEmitente,
              cidadeDestinatario: item.cidadeDestinatario,
              situacao: this.getStatus(item.status, item.tipo),
              chave: item.chaveAcesso,
              dactePath: item.dactePath,
              xmlPath: item.xmlPath,
              cfop: item.cfop ? item.cfop : "-",
              cst: item.cst ? item.cst : "-",
            });
          });
          this.showLoader = false;
        })
        .catch(() => {
          this.listaCtes = [];
          this.showLoader = false;
          window.location.reload();
        });
    },

    createZipFile() {
      setTimeout(() => {
        var zip = new JSZip.default();
        for (var i = 0; i < this.listaBlobs.length; i++) {
          zip.file(this.listaBlobs[i].nome, this.listaBlobs[i].data);
        }
        if (this.formatoSelecionado == 0) {
          if (
            this.listaBlobs.length == 0 ||
            this.listaBlobs.length < this.selected.length * 2
          ) {
            this.createZipFile();
            return;
          }
        }
        if (this.formatoSelecionado == 1 || this.formatoSelecionado == 2) {
          if (
            this.listaBlobs.length == 0 ||
            this.listaBlobs.length < this.selected.length
          ) {
            this.createZipFile();
            return;
          }
        }
        zip.generateAsync({ type: "blob" }).then((blob) => {
          saveAs(blob, "file.zip");
        });
        this.baixando = false;
        this.listaBlobs = [];
      }, "1000");
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    downloadTable() {
      let data = [
        {
          sheet: "Relatorio",
          columns: this.fieldsXls,
          content: this.listaCtes,
        },
      ];

      const currentDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
      currentDate.setHours(currentDate.getHours() + 3); 

      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");

      let settings = {
        fileName: `Relatório - ${day}_${month}_${year} ${hours}${minutes}${seconds}`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
      };

      xlsx(data, settings);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getColor(status) {
      if (status == "Enviado" || status == "Gerado") {
        return "#1565C0";
      } else if (status == "Autorizado") {
        return "#068206";
      } else if (status == "Rejeitado") {
        return "#F44336";
      } else if (status == "Inutilizado") {
        return "#00897B";
      } else if (status == "Cancelado") {
        return "#F44336";
      } else if (status == "Denegado") {
        return "#212121";
      }
    },
    getStatus(status, tipo) {      
      if (status == 1) {
        return "Enviado";
      } else if (status == 2) {
        return "Autorizado";
      } else if (status == 3) {
        return "Rejeitado";
      } else if (tipo == "CT-e" && status == 4) {
        return "Inutilizado";
      } else if (tipo == "MDF-e" && status == 4) {
        return "Encerrado";
      } else if (status == 5) {
        return "Cancelado";
      } else if (status == 6) {
        return "Denegado";
      } else if (status == 0) {
        return "Gerado";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-treeselect__control {
  border-radius: 0;
}

.responsivo {
  position: absolute;
  top: -100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    position: relative;
    top: 0%;
  }
}
</style>
